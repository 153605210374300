/**
 * Constants
 */

export const OVERWRITE_ACCOUNTS = 'accounts/OVERWRITE_ACCOUNTS';
export const ADD_ACCOUNTS = 'accounts/ADD_ACCOUNTS';


/**
 * Action Creators
 */
export function overwriteAccounts(data) {
    return { type: OVERWRITE_ACCOUNTS, payload: data }; 
}

export function addAccounts(data) {
    return { type: ADD_ACCOUNTS, payload: data }; 
}


/**
 * Reducer
 */
const initialState = []

export default function accounts(state = initialState, action) {
    switch (action.type) {
        
        case OVERWRITE_ACCOUNTS:
            // Overwrite everything with the payload (fresh fetched data)
            return action.payload;

        case ADD_ACCOUNTS:
        	let a1 = state;
        	let a2 = action.payload;

        	let a3 = a1.concat(a2).reduce((acc, x) => {
    			acc[x.id] = Object.assign(acc[x.id] || {}, x);
    			return acc;
			}, {});

            return Object.values(a3);
        
        default:
            return state
    }
}