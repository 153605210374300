import NewFormAccountPage from './';
import { Get } from '../../services/ApiRequest';

export default {
    name: 'new-form-account',
    url: '/new-form-account',
    component: NewFormAccountPage,
    resolve: [
    	{
            token: 'packages',
            deps: ['$transition$'],
            resolveFn: (trans) => Get('/packages')
        }
    ]
};