import { Get, Post, Delete } from '../../../../services/PortalRequest';

const ChangelogServiceRepository = () => {

    return {
        create: (data) => {
            return Post('/changelog', data);
        },

        update: (data) => {
            return Post('/changelog', data);
        },

        getById: (id) => {
            if (!id) {
                throw new Error('Expected ID to get');
            }

            return Get(`/changelog/${id}`);
        },

        getPage: (page = null) => {
            const pageQueryParam = page ? `&page=${page}` : '';
            return Get(`/changelog/?app=geckoform&perPage=25${pageQueryParam}`);
        },

        delete: (id) => {
            return Delete(`/changelog/${id}`);
        },
    }
};

export default ChangelogServiceRepository;
