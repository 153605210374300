import './index.css';
import React, { Component } from 'react';
import { $state, $stateParams } from '../../router/';
import countries  from '../../Logic/misc/countries';
import currencies from '../../Logic/misc/currencies';
import regions from '../../Logic/misc/regions';
import timezones  from '../../Logic/misc/timezones';
import { GECKO_CONSTS, GECKO_UTILS, GeckoToastService, GeckoDialogService, GeckoCardForm } from '@geckolabs/gecko-react-ui';
import NewAccountControls from '../../components/new-account-btns';

const BLANK_DEFAULTS = {
    //region            : null,
    default_country   : null,
    short_date_format : null,
    time_format       : null,
    timezone          : null,
    currency          : null,
};

const EU_DEFAULTS = {
    //region            : 'eu',
    default_country   : 'GB',
    short_date_format : 'd/m/Y',
    time_format       : 'H:i',
    timezone          : 'Europe/London',
    currency          : 'GBP',
};

const US_DEFAULTS = {
    //region            : 'us-e',
    default_country   : 'US',
    short_date_format : 'm/d/Y',
    time_format       : 'g:i A',
    timezone          : 'America/New_York',
    currency          : 'USD',
};

export class NewFormAccountPage extends Component {

    state = {
        address: {
            street_address : '',
            city           : '',
            state          : '',
            country        : '',
            postal_code    : '',
        },
        user: {
            full_name: '',
            email: ''
        }, 
        values: {
            region            : null,
            name              : '',
            short_name        : '',
            package_id        : null

        }
    }

    constructor(props) {
        super(props);
        // Set default values
        this.state.values = Object.assign(this.state.values, BLANK_DEFAULTS);
    }

    getRegionDefaults(region) {
        switch(region) {
            case 'eu':
                return EU_DEFAULTS;
            break;
            case 'us-e':
                return US_DEFAULTS;
            break;
            case 'stage':
                return EU_DEFAULTS;
            break;
            case 'local':
                return EU_DEFAULTS;
            break;
            default:
                return BLANK_DEFAULTS;
        }
    }

    changeHandler(obj, key, value) {
        // Set region defaults
        if (key === 'region') {
            const values = Object.assign({}, this.state.values, {[key]: value}, this.getRegionDefaults(value));
            return this.setState({values});
        }

        if (obj === 'user') {
            const user = Object.assign({}, this.state.user, {[key]: value});
            return this.setState({user});
        }

        if (obj === 'address') {
            const address = Object.assign({}, this.state.address, {[key]: value});
            return this.setState({address});
        }

        if (value && key === 'short_name') value = this.prepareShortName(value);

        const values = Object.assign({}, this.state.values, {[key]: value});
        this.setState({values});
    }

    prepareShortName(value) {
        return value.replace(new RegExp(/\s/, 'g'), '-').replace(new RegExp(/[^a-zA-Z0-9_-]/, 'g'), '').toLowerCase().slice(0, 10);
    }

    fieldIsDisabled() {
        return !this.state.values.region
    }

    userFields = [
        {
            key          : 'full_name',
            label        : 'Full Name',
            type         : GECKO_CONSTS.FIELD_TYPE_NAME,
            width        : '100%',
            required     : true,
        },
        {
            key          : 'email',
            label        : 'Email',
            width        : '100%',
            type         : GECKO_CONSTS.FIELD_TYPE_EMAIL,
            required     : true,
        },
    ];

    companyFields = [
        {
            key         : 'name',
            label       : 'Company',
            width       : '50%',
            required    : true,
            description : `Used throughout Gecko to identify this account.`,
            onBlur      : () => {
                if (this.state.values.name && !this.state.values.short_name) {
                    this.changeHandler('values', 'short_name', this.state.values.name);
                }
            }
        },
        {
            key         : 'short_name',
            label       : 'Unique account identifier',
            width       : '50%',
            required    : true,
            description : `For use in personalised weblinks (urls) such as Landing Pages.`
        }
    ];

    addressFields = [
        {
            key          : 'street_address',
            label        : 'Street Address',
            required     : true,
            width        : '100%',
        },
        {
            key          : 'city',
            label        : 'City',
            required     : true,
            width        : '50%',
        },
        {
            key          : 'state',
            label        : 'State',
            required     : true,
            width        : '50%',
        },
        {
            key          : 'postal_code',
            label        : 'Post Code / Zipcode',
            required     : true,
            width        : '50%',
        },
        {
            key          : 'country',
            label        : 'Country',
            required     : true,
            width        : '50%',
            type         : GECKO_CONSTS.FIELD_TYPE_SELECT,
            options      : countries.map(c => ({value: c.iso2, label: c.name})),
            config       : {clearable: false} 
        },
        {
            type        : GECKO_CONSTS.FIELD_TYPE_DISPLAY,
            width       : '100%',
            description : 'The address is used across the system for features such as event locations, VOIP number purchasing, emails and more.',
        },
    ];

    settingsFields = [
        {
            key          : 'region',
            label        : 'Region',
            width        : '100%',
            type         : GECKO_CONSTS.FIELD_TYPE_SELECT,
            required     : true, 
            options      : regions(),
            clearable    : true,
            config       : {clearable: false}  
        },
        {
            type         : GECKO_CONSTS.FIELD_TYPE_DISPLAY,
            width        : '100%',
            description  : 'The region where the account will be created.',
        },
        {
            type         : GECKO_CONSTS.FIELD_TYPE_SELECT,
            key          : 'package_id',
            label        : 'Package',
            options      : this.props.packages.map(p => ({value: p.id, label: p.title})),
            required     : true,
            width        : '100%',
            config       : {clearable: false}  
        },
        {
            type         : GECKO_CONSTS.FIELD_TYPE_DISPLAY,
            width        : '100%',
            description  : 'The package to create the account with.',
        },
        {
            type         : GECKO_CONSTS.FIELD_TYPE_SELECT,
            key          : 'default_country',
            label        : 'Default Country',
            options      : countries.map(c => ({value: c.iso2, label: c.name})),
            width        : '100%',
            disabledWhen : this.fieldIsDisabled.bind(this),
            config       : {clearable: false}  
        },
        {
            type        : GECKO_CONSTS.FIELD_TYPE_DISPLAY,
            width       : '100%',
            description : 'The default country code to be applied to all imported contacts with a mapped telephone number.',
        },
        {
            type         : GECKO_CONSTS.FIELD_TYPE_SELECT,
            key          : 'short_date_format',
            label        : 'Date Format',
            width        : '33.33%',
            options      : [{value: 'd/m/Y', label: 'dd/mm/yyyy'}, {value: 'm/d/Y', label: 'mm/dd/yyyy'}],
            required     : true,
            disabledWhen : this.fieldIsDisabled.bind(this),
            config       : {clearable: false}  
        },
        {
            type         : GECKO_CONSTS.FIELD_TYPE_SELECT,
            key          : 'time_format',
            label        : 'Time Format',
            width        : '33.33%',
            options      : [{value: 'g:i A', label: '12hr (am/pm)'}, {value: 'H:i', label: '24hr'}],
            required     : true,
            disabledWhen : this.fieldIsDisabled.bind(this),
            config       : {clearable: false}  
        },
        {
            type         : GECKO_CONSTS.FIELD_TYPE_SELECT,
            key          : 'timezone',
            label        : 'Timezone',
            width        : '33.33%',
            options      : timezones.map(z => ({value: z, label: z})),
            required     : true,
            disabledWhen : this.fieldIsDisabled.bind(this),
            config       : {clearable: false}  
        },
        {
            type        : GECKO_CONSTS.FIELD_TYPE_DISPLAY,
            width       : '100%',
            description : 'Used when scheduling events, calls or messages.',
        },
        {
            type         : GECKO_CONSTS.FIELD_TYPE_SELECT,
            key          : 'currency',
            label        : 'Billing Currency',
            options      : currencies.map(c => ({value: c.code, label: `${c.code} (${c.name})`})),
            required     : true,
            width        : '100%',
            disabledWhen : this.fieldIsDisabled.bind(this),
            config       : {clearable: false}  
        },
        {
            type         : GECKO_CONSTS.FIELD_TYPE_DISPLAY,
            width        : '100%',
            description  : 'The currency of the account.',
        },
    ];

    btns = [{
        name    : 'Create Form Account',
        icon    : 'check',
        preset  : GECKO_CONSTS.BTN_PRESET_SAVE,
        handler : this.submitHandler.bind(this)
    }];

    renderConfirmAlert() {
        return (
            <div className="create-form-account-alert">
                <p>Create an account with the following settings?</p>
                <ul>
                    <li>Name: <strong>{(this.state.values.name || '') || 'n/a'}</strong></li>
                    <li>Region: <strong>{(this.state.values.region || '').toUpperCase() || 'n/a'}</strong></li>
                    <li>Package: <strong>{(this.props.packages.find(p => p.id === this.state.values.package_id) || {}).title || 'n/a'}</strong></li>
                </ul>
            </div>
        );
    }


    prepareData() {
        const data = this.state.values;
        // User obj
        const user = this.state.user;
        // Ensure user full_name is a string
        user.full_name = `${user.full_name.first_name} ${user.full_name.last_name}`;
        // Address obj
        const address = this.state.address;
        if (data.name) address.customer_name = data.name;

        return Object.assign({}, data, {user}, {address});
    }

    regionUrls = {
        'eu'    : 'https://api-eu.geckoform.com',
        'us-e'  : 'https://api-us-e.geckoform.com',
        'stage' : 'https://api-stage.geckoform.com',
        'local' : 'http://api.geckoform.test:8000',       
    };

    saveFn() {
        const endpoint = `${this.regionUrls[this.state.values.region]}/accounts/register`;
        
        return fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.prepareData())
        })
        .then(res => {
            // Throw Error
            if(res.status !== 200) { 
                return res.json().then(err => {
                    throw new Error(err.name);
                }); 
            }
            return res.json();
        });
    }

    submitHandler() {
        return new GeckoDialogService().confirm(this.renderConfirmAlert()).then(ok => {
            if (ok) { 
                this.saveFn().then(alert => {
                    // Success Toast
                    new GeckoToastService().success(GECKO_UTILS.renderError(alert));
                    // Redirect
                    return $state.go('dashboard');
                }).catch(err => new GeckoToastService().error(GECKO_UTILS.renderError(err)));
            }
        });
    }

    render() {
        return (
            <div className="mainContentWrapper p-2--sm p-5">
                <NewAccountControls />
                <GeckoCardForm 
                   name="Primary User"
                   fields={this.userFields} 
                   values={this.state.user} 
                   handler={this.changeHandler.bind(this, 'user')} />
               <GeckoCardForm 
                   name="Company Details"
                   fields={this.companyFields} 
                   values={this.state.values} 
                   handler={this.changeHandler.bind(this, 'values')} />
                <GeckoCardForm 
                   name="Company Address"
                   fields={this.addressFields} 
                   values={this.state.address} 
                   handler={this.changeHandler.bind(this, 'address')} />
                <GeckoCardForm 
                   name="Account Settings"
                   fields={this.settingsFields} 
                   values={this.state.values} 
                   handler={this.changeHandler.bind(this, 'values')} 
                   submitHandler={this.submitHandler.bind(this)}
                   footerBtns={this.btns} />
            </div>
        );
    }

}

export default NewFormAccountPage;