export const Utils = {
	capitalize: (str) => {
	    str = str.split(" ");

	    for (var i = 0, x = str.length; i < x; i++) {
	        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
	    }

	    return str.join(" ");
	},

	getContrastColor: (hexcolor) => {
        console.log(hexcolor, 'hexcolor in utils');
        if (!hexcolor) return 'white';
        hexcolor = hexcolor.indexOf('#') > -1 ? hexcolor.replace('#', '') : hexcolor;
        let r = parseInt(hexcolor.substr(0, 2), 16);
        let g = parseInt(hexcolor.substr(2, 2), 16);
        let b = parseInt(hexcolor.substr(4, 2), 16);
        let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? '#1a252f' : 'white';
    },

    decodeHtmlEntity: (str) => {
        if(!str) {return ''};
        
  		return str.replace(/&#(\d+);/g, function(match, dec) {
    		return String.fromCharCode(dec);
  		});
	},

    /**
    * The array_get function retrieves a value from a deeply nested array using "dot" notation
    * The array_get function also accepts a default value, which will be returned if the specific key is not found
    *
    */
    array_get: (object, key, defaultVal) => {
        if (typeof defaultVal === 'undefined') { defaultVal = null; }
        if (!key) return object;
        
        var s = key.split('.');
        
        var o = object;
        
        for(var x=0;x < s.length; x++)
        {
            if (null !== o && o.hasOwnProperty(s[x]))
            {
                o = o[s[x]];
            }
            else
            {
                return defaultVal;
            }
        }
        
        return o;
    },

    numberWithCommas: (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    pick: (o, ...fields) => {
        return fields.reduce((a, x) => {
            if(o.hasOwnProperty(x)) a[x] = o[x];
            return a;
        }, {});
    },
    guid: () => {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    },

    /**
     * 
     * Flatten a deep object into a one level object with it’s path as key
     *
     * @param  {object} object - The object to be flattened
     * @return {object} - The resulting flat object
     * 
     * @see https://gist.github.com/penguinboy/762197#gistcomment-2008512
     */
    flatten: (object, maintain) => {
        return Object.assign( {}, ...function _flatten( objectBit, path = '' ) {  //spread the result into our return object
            return [].concat(                                                       //concat everything into one level
                ...Object.keys( objectBit ).map(                                      //iterate over object
                    key => typeof objectBit[ key ] === 'object' ?                       //check if there is a nested object
                        _flatten( objectBit[ key ], `${ key }` ) :              //call itself if there is
                        ( { [ `${ key }` ]: objectBit[ key ] } )                //append object with it’s path as key
                )
            )
        }( object ) );
    },

    except: (obj, keys) => {
        return Object.keys(obj)
            .filter(k => !keys.includes(k))
            .map(k => Object.assign({}, {[k]: obj[k]}))
            .reduce((res, o) => Object.assign(res, o), {});
    }
}

export default Utils;