// Core
import React, { Component } from 'react';

// Style
import './composer.css';

// Components
import { 
    GECKO_CONSTS,
    GeckoCard,
    GeckoCardForm
} from '@geckolabs/gecko-react-ui';


export default class Composer extends Component {

    constructor(props) {
        super();

        this.initialState = {
            openToEdit: false,
            id: null,
            title: '',
            releaseType: '',
            body: '',
            link: '',
        }

        this.state = {
            composing: false,
            ...this.initialState,
        }

        // Bind
        this.renderComposer = this.renderComposer.bind(this);
        this.renderOpenComposer = this.renderOpenComposer.bind(this);
        this.renderClosedComposer = this.renderClosedComposer.bind(this);
        this.toggleComposer = this.toggleComposer.bind(this);
        this.focusFirst = this.focusFirst.bind(this);
        this.create = this.create.bind(this);
        this.handleComposerChange = this.handleComposerChange.bind(this);
        this.getEditableFields = this.getEditableFields.bind(this);
        this.reset = this.reset.bind(this);
        this.closeAndReset = this.closeAndReset.bind(this);
        this.set = this.set.bind(this);
        this.edit = this.edit.bind(this);
        this.close = this.close.bind(this);
    }


    componentDidUpdate(prevProps, prevState) {
        if(prevState.composing == false && this.state.composing == true) {
            this.focusFirst();
        }
    }


    /* =========================

        Global Component Methods
    
    ========================= */

    toggleComposer() {
        this.setState({
            composing: !this.state.composing,
        })
    }

    open() {
        this.setState({
            composing: true,
        })
    }

    close() {
        this.setState({
            openToEdit: false,
            composing: false,
        })
    }

    openToEdit() {
        this.setState({
            composing: true,
            openToEdit: true,
        })
    }

    focusFirst(){
        let firstField = this.getEditableFields()[0].key;

        let inputId = 'field_' + firstField;

        document.getElementById(inputId).focus();
    }


    getEditableFields() {
        if(this.props.fields) {

            if(!this.state.openToEdit) {
                return this.props.fields.filter(c => c.key !== 'id');
            }

            return this.props.fields;
        }

        return [];
    }


    closeAndReset() {
        this.close();
        this.reset();
    }


    reset() {
        this.setState({
            ...this.initialState,
        })
    }

    set(values) {
        this.setState({...values});
    }

    create() {
        this.props.create(this.state)
        .then(() => this.closeAndReset());
    }

    edit() {
        this.props.edit(this.state)
        .then(() => this.closeAndReset());
    }
    

    /* =========================

        Render Helpers
    
    ========================= */


    handleComposerChange(key, val) {
        this.setState({
            [key]: val,
        });
    }


    /* =========================

        Renders
    
    ========================= */


    /**
    *
    * Render a form to compose a new changelog
    */
    renderComposer() {

        let openClass = (this.state.composing ? 'open' : '');

        return (

             <div className={'composer-container ' + openClass}>
                {this.state.composing &&
                    this.renderOpenComposer()
                }

                {!this.state.composing &&
                    this.renderClosedComposer()
                }
            </div>

        )
        
        
    }

    renderOpenComposer() {

        let footerBtns = [{
            name: 'Cancel',
            handler: this.closeAndReset,
        }];

        if(this.state.openToEdit) {
            footerBtns.push({
                name: 'Update',
                type: GECKO_CONSTS.BTN_TYPE_PRIMARY,
                handler: this.edit,
            });
        } else {
            footerBtns.push({
                name: 'Create',
                type: GECKO_CONSTS.BTN_TYPE_PRIMARY,
                handler: this.create,
            });
        }

        return (

            
                <GeckoCardForm 
                    name="New Changelog"
                    fields={this.getEditableFields()}
                    values={this.state}
                    footerBtns={footerBtns}
                    handler={this.handleComposerChange}>
                </GeckoCardForm>
        );
    }


    renderClosedComposer() {

        const style = {
            padding: '10px',
            fontSize: '20px',
            fontWeight: '300',
            color: 'rgba(0,0,0,0.4)',
            cursor: 'text',
        };

        return (
            <GeckoCard>
                <div className="" style={style} onClick={this.toggleComposer}>
                    Create a new changelog...
                </div>
            </GeckoCard>
        );
    }


  

    render() {
        return (
            <div className="row">
                <div className="col">
                    {this.renderComposer()}
                </div>
            </div>
        );
    }

}
