import AccountPage from './account';
import { Get } from '../../services/ApiRequest';

export default {
    name: 'account',
    url: '/accounts/:accountId',
    component: AccountPage,
    resolve: [
        {
            token: 'data',
            deps: ['$transition$'],
            resolveFn: (trans) => {
                // Only trigger 
                if (!trans.params().accountId) return {};

                return Get(`/accounts/${trans.params().accountId}`, {include: 'package,limits'});
            }
        },
        {   
            token: 'account',
            deps: ['data'],
            resolveFn: (data) => data.account || {},
        },
        {   
            token: 'package',
            deps: ['data'],
            resolveFn: (data) => (data.account && data.account.package ? data.account.package : {}),
        },
        {   
            token: 'limits',
            deps: ['data'],
            resolveFn: (data) => (data.account && data.account.package && data.account.package.limits ? data.account.package.limits : {}),
        },
        {   
            token: 'accountId',
            deps: ['$transition$'],
            resolveFn: (trans) => trans.params().accountId,
        },
    ]
};