// Core
import React, { Component } from 'react';
import { $state, $stateParams } from '../../../../router/';

// Logic
import AccountLogic from '../../../../Logic/Account';

// Components
import {
    GECKO_CONSTS,
    GeckoFields,
    GeckoBtnsSorted, 
    GeckoModal,
    GeckoModalService
} from '@geckolabs/gecko-react-ui';
import Interweave from "interweave";

export default class VoipTopUp extends Component {

    constructor(props) {
        super();

        this.state = {
            account_uuid: props.account_uuid,
            balance: props.balance,
            amount: '',
            currency: props.currency,
        }

        // Binds
        this.store = this.storeConfirm.bind(this);
    }

    fields = [
        {
            key: 'amount',
            val: null,
            label: 'Amount of VOIP Credit to add',
            type: GECKO_CONSTS.FIELD_TYPE_NUMBER,
            width: '100%',
            required: true,
        }
    ];

    fieldIsDisabled() {
        return !this.state.amount;
    }

    storeConfirm() {
        let btns = [{
            name: 'Cancel',
            preset: GECKO_CONSTS.BTN_PRESET_CLOSE,
            handler: () => {
                return new GeckoModalService().close();
            }
        },{
            name: 'Add Transaction',
            preset: GECKO_CONSTS.BTN_PRESET_SAVE,
            handler: () => {
                AccountLogic.addTransaction(this.state)
                    .then((response) => {
                        $state.go($state.$current.name, $stateParams, {reload: true});
                        new GeckoModalService().close();
                    });
            }
        }];

        let amount = <Interweave content={this.state.amount} />;

        return new GeckoModalService().open(() => {
            return (
                <GeckoModal name="Add Transaction" btns={btns}>
                    <div className="text-center">
                        Are you sure you want to add <span className="font-weight-bold">{this.state.currency}{amount} as VOIP credit</span>
                    </div>
                </GeckoModal>
            );
        });
    }

    modalButtons = [
        {
            name: 'Cancel',
            preset: GECKO_CONSTS.BTN_PRESET_CLOSE,
            handler: () => {
                return new GeckoModalService().close();
            },
        },
        {
            name: 'Add Transaction',
            preset: GECKO_CONSTS.BTN_PRESET_SAVE,
            disabledWhen: this.fieldIsDisabled.bind(this),
            handler: this.storeConfirm.bind(this)
        }
    ];

    /* =========================
        Global component methods
    ========================= */
    handleChange(key, val) {
        this.setState({
            [key]: val,
        });
    }

    /* =========================
        Render Helpers
    ========================= */

    /* =========================
        Renders
    ========================= */
    render() {
        return (
            <div className="">

                <GeckoFields fields={this.fields} values={this.state} handler={this.handleChange.bind(this)} />
                
                <div className="modal-footer">
                    <GeckoBtnsSorted items={this.modalButtons} />
                </div>
            </div>
        );
    }
}
