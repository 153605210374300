import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { unregister } from './registerServiceWorker';

const bootApp = () => {
	ReactDOM.render(<App />, document.getElementById('root'));
	unregister();
};

bootApp();