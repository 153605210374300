import './app-header.css';
import React, { Component } from 'react';
import { router, $state } from '../../router/';
import { GeckoBtn } from '@geckolabs/gecko-react-ui';
import Sidebar from "react-sidebar";
import AppSidebar from '../app-sidebar/AppSidebar';
import Logout from '../../Gecko/Auth/Application/Logout';
import MyApps from '../../Gecko/Auth/Application/MyApps';


export class AppHeader extends Component {

	state = {
		showBackBtn: false,
		sidebarOpen: false,
	}

	onSetSidebarOpen(open) {
		this.freezeBody(open);
    	this.setState({ sidebarOpen: open });
  	}

  	freezeBody(shouldFreeze)
  	{
  		if (shouldFreeze) {
  			document.body.style.overflow = 'hidden';
  		} else {
  			document.body.style.overflow = 'auto';
  		}
  	}

	componentDidMount() {
		this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);

		router.transitionService.onSuccess(true, (trans) => {
			if ($state.$current.name === 'settings') {
				this.setState({['showBackBtn']: true});
			} else {
				this.setState({['showBackBtn']: false});
			}
		});
	}

	renderBtn() {
		return (
			<GeckoBtn 
				className="menu"
				icon="bars" 
				handler={() => this.onSetSidebarOpen(true)}
			/>
		);
		
	}

	renderAvatar() {

	}

	renderMyApps() {
		return <GeckoBtn name="My Apps" className="btn-default" handler={() => MyApps()} />
	}

	renderLogout() {
		return <GeckoBtn name="Log Out" className="btn-danger" handler={() => Logout()} />
	}

    render() {
    	return (
    		<div className={this.state.sidebarOpen ? 'menu-is-open' : 'menu-is-closed'}>
    			<Sidebar
			        sidebar={<AppSidebar />}
			        open={this.state.sidebarOpen}
			        onSetOpen={this.onSetSidebarOpen}
			        styles={{ sidebar: { background: "white" } }}
      			/>
	    		<div className="app-header">
	    			<div className="row justify-content-between">
	    				<div className="col">
	    					{this.renderBtn()}
	    				</div>

	    				<div className="col d-flex justify-content-end">
							{this.renderMyApps()}
	    					{this.renderLogout()}
	    				</div>
	    			</div>
	    		</div>
	    	</div>
    	);
    }

}

export default AppHeader;