// Redux
import { compose, combineReducers, applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import RootReducer from '../../modules';
import { offline } from 'redux-offline';
import offlineConfig from 'redux-offline/lib/defaults';
import thunkMiddleware from 'redux-thunk'

// Services
// import GeckoRequest from '../GeckoRequest';

let reduxStore = null;

function configureStore() {
    let customConfig = {
        ...offlineConfig,
        // effect: (effect, _action) => GeckoRequest.post(effect),
        rehydrate: true,
    }

    const finalCreateStore = compose(
      applyMiddleware(thunkMiddleware),
      offline(customConfig)
    )(createStore)

    const store = finalCreateStore(
        RootReducer, 
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(), // enable redux dev tools https://github.com/zalmoxisus/redux-devtools-extension#usage
    )

    // Assign to "global"
    reduxStore = store;

    return store
}


const getNestedValue = (object, key) => key.split('.').reduce((obj, keyPart) => obj[keyPart], object);

export default {
    get(key) {
        if(!key) { return reduxStore.getState(); }
        return getNestedValue(reduxStore.getState(), key);
    },
    set(key, value) {
        console.error('GeckoStore: Redux should only be updated by dispatching an action http://redux.js.org/docs/basics/Actions.html have a look at GeckoStore.redux.dispatch() instead')
    },
    dispatch(...args) {
        if(reduxStore) {
            reduxStore.dispatch(...args);
        } else {
            console.log('reduxStore doesnt exist.');
        }
    },
    init: configureStore,
};
