const ChangelogRepository = (provider) => {
    return { 
        create: (data) => provider.create(data),
        update: (data) => provider.update(data),
        getById: (id) => provider.getById(id),
        getPage: (page = null) => provider.getPage(page),
        delete: (id) => provider.delete(id),
    }
}

export default ChangelogRepository;
