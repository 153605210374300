// Core
import React, { Component } from 'react';

// Router
import { UISref } from '@uirouter/react';
import { $state, $stateParams } from '../../router/';

// Redux
import { connect } from 'react-redux';

import { GeckoCardTable } from '@geckolabs/gecko-react-ui';


export class AccountsPage extends Component {

    cols() {
        return [
            {
                key: 'id',
                name: 'Id',
                class: 'block',
                sortable: true,
                render: (value, row) => (<UISref to={'account'} params={{accountId: row.id}}><a className="pointer block">{value}</a></UISref>),

            }, {
                key: 'name',
                name: 'Account',
                class: 'block',
                sortable: true,
                render: (value, row) => (<UISref to={'account'} params={{accountId: row.id}}><a className="pointer block">{value}</a></UISref>),
            },
        ]
    }

    pagination() {
        let pagination = this.props.pagination;
        pagination.changePage = (page) => {
            return $state.go($state.$current.name, {page: page})
        }
        return pagination;
    }

    searchHandler(keyword, event) {
        return $state.go('accounts', {keyword: keyword});
    }
    
    renderAccountsTable() {
        return (
            <GeckoCardTable 
                name="Accounts"
                cols={this.cols()}
                rows={this.props.accounts || []}
                pagination={this.pagination()} 
            />
        );
    }

    render() {
        return (
            <div className="mainContentWrapper p-2--sm p-5">
                {this.renderAccountsTable()}
            </div>
        );
    }

}

/* ============================

    Redux

============================ */

const mapStateToProps = (state) => {
    return {
        ...state,
    }
}


export default connect(mapStateToProps)(AccountsPage);


