import React, { Component } from 'react';
import { $state } from '../../router/';
import './changelog.css';
import { connect } from 'react-redux';
import { addChangelogs, updateChangelog, deleteChangelog } from '../../modules/changelogs';
import { Gecko } from '../../Logic';
import {
    GECKO_CONSTS,
    GeckoToastService,
    GeckoIcon,
    GeckoModal,
} from '@geckolabs/gecko-react-ui';
import { GeckoModalService } from '@geckolabs/gecko-react-ui';
import { UncontrolledTooltip } from 'reactstrap';
import Collapsible from '../../components/Collapsible';
import Composer from './components/Composer';
import Spinner from '../../components/Spinner';
import Interweave from 'interweave';
import GeckoStore from '../../services/GeckoStore';
import Utils from '../../Utils';
import { ChangelogRepository } from '../../Gecko/Changelogs';
import moment from "moment";


export class ChangelogsPage extends Component {

    constructor(props) {
        super();

        this.state = {
            isInfiniteLoading: false,
            nextPage: props.pagination.next,
        }

        // Bind
        this.prepareSections = this.prepareSections.bind(this);
        this.renderCollapsible = this.renderCollapsible.bind(this);
        this.create = this.create.bind(this);
        this.handleInfiniteLoad = this.handleInfiniteLoad.bind(this);
        this.elementInfiniteLoad = this.elementInfiniteLoad.bind(this);
        this.scrollListener = this.scrollListener.bind(this);
        this.createButtonArrayForChangelog = this.createButtonArrayForChangelog.bind(this);
        this.openToEdit = this.openToEdit.bind(this);

    }

    componentWillMount() {
        window.addEventListener('scroll', this.scrollListener);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollListener);
    }

    /* =========================

        Global Component Methods
    
    ========================= */

    fetchData(page) {
        return ChangelogRepository.getPage(page);
    }

    create(formData = {}) {
        const {
            title,
            releaseType,
            body,
            link,
        } = Utils.pick(formData, 'title', 'releaseType', 'body', 'link');

        if ([title, releaseType, body].some((value) => !value)) { 
            new GeckoToastService().error('Missing required field');
            return Promise.reject();
        }
        
        return ChangelogRepository.create({
            title,
            releaseType,
            body,
            link: link && link.length > 0 ? link : null,
            app: 'geckoform'
        }).then((response) => {
            return ChangelogRepository.getById(response.data.id);
        }).then((response) => {
            GeckoStore.redux.dispatch(updateChangelog(response.data));
        }).catch((errorResponse) => {
            if (errorResponse && errorResponse.errors && errorResponse.errors.length > 0) {
                new GeckoToastService().error(errorResponse.errors[0].detail);
            } else {
                new GeckoToastService().error(JSON.stringify(errorResponse));
            }
        })
    }

    edit(formData = {}) {
        const data = Utils.pick(formData, 'id', 'title', 'releaseType', 'body', 'link');

        return ChangelogRepository.update(data)
            .then((response) => {
                return ChangelogRepository.getById(data.id);
            }).then((response) => {
                new GeckoToastService().success('Changelog updated');
                GeckoStore.redux.dispatch(updateChangelog(response.data));
            }).catch((errorResponse) => {
                if (errorResponse && errorResponse.errors && errorResponse.errors.length > 0) {
                    new GeckoToastService().error(errorResponse.errors[0].detail);
                } else {
                    new GeckoToastService().error(JSON.stringify(errorResponse));
                }
            });
    }

    handleInfiniteLoad() {
        var _this = this;

        if (this.state.isInfiniteLoading || this.state.nextPage === null ) {
            return;
        }

        this.setState({
            isInfiniteLoading: true
        });

        this.fetchData(this.state.nextPage)
            .then(({data, pagination}) => {
                _this.setState({
                    isInfiniteLoading: false,
                    nextPage: pagination.next,
                });

                GeckoStore.redux.dispatch(addChangelogs(data));
            }).catch(() => {
                _this.setState({
                    isInfiniteLoading: false,
                });
            });
    }

    scrollListener() {
        if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 200) {
            this.handleInfiniteLoad();
        }
    }

    /* =========================

        Models
    
    ========================= */

    /**
    *
    * Get all the gecko fields that can be edited in a changelog
    * @returns (Array) - GeckoFields array
    */
    getEditableFields() {
        return [{
            key: 'id',
            label: 'Id',
            type: GECKO_CONSTS.FIELD_TYPE_TEXT,
            width: '100%',
            disabledWhen: () => true,
            hideWhen: () => {
                return false;
            }
        },
        {
            key: 'title',
            label: 'Title',
            type: GECKO_CONSTS.FIELD_TYPE_TEXT,
            width: '100%',
            required: true
        }, {
            key: 'releaseType',
            label: 'Release Type',
            type: GECKO_CONSTS.FIELD_TYPE_SELECT,
            width: '100%',
            optionValueKey: 'value',
            optionsLabelKey: 'label',
            required: true,
            options: [
                {
                    label: 'General Release',
                    value: 'general',
                },
                {
                    label: 'Feature Release',
                    value: 'feature',
                },
                {
                    label: 'Mobile App Update',
                    value: 'mobile_app',
                },
            ]
        }, {
            key: 'body',
            label: 'Body',
            width: '100%',
            required: true,
            type: GECKO_CONSTS.FIELD_TYPE_TEXTAREA
        }, {
            key: 'link',
            label: 'Link',
            type: GECKO_CONSTS.FIELD_TYPE_TEXT,
            width: '100%',
            required: false
        }];
    }

    /* =========================

        Render Helpers
    
    ========================= */

    /**
    *
    * Loop rounds all the change logs and put them into a structure for the collapsible
    * @returns (Array) - of changelog data with a render methods
    */
    prepareSections() {
        let data = this.props.changelogs;

        data.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
        });

        // Mutate the date to conform
        return data.map((item, key) => {
            return {
                title: item.title,
                render: this.renderSingleChangelog(item),
                renderTitle: this.renderSingleTitle,
                buttonArray: this.createButtonArrayForChangelog(item),
            }
        });
    }

    createButtonArrayForChangelog(item) {
        return [
            {
                title: 'Delete Changelog',
                render: () => {
                    return (
                        <GeckoIcon icon="trash" />
                    )
                },
                onClick: this.deleteChangelogConfirm.bind(this, item),
            }, {
                title: 'Edit Changelog',
                render: () => {
                    return (
                        <GeckoIcon icon="pencil" />
                    )
                },
                onClick: this.openToEdit.bind(this, item),
            }
        ]
    }

    openToEdit(item) {


        this.refs['composer'].set({
            id: item.id,
            title: item.title,
            releaseType: item.releaseType,
            body: item.body,
            link: item.link,
        });

        window.scrollTo({
            "behavior": "smooth",
            "top": 0,
        });

        this.refs['composer'].openToEdit();
    }

    deleteChangelog(changelog) {

        return ChangelogRepository.delete(changelog.id)
            .then(() => {
                new GeckoToastService().success('Changelog Deleted');
                GeckoStore.redux.dispatch(deleteChangelog(changelog.id));

            }).catch(function (err) {
                new GeckoToastService().error(err);
            });
    }

    deleteChangelogConfirm(changelog) {
        let btns = [{
            name: 'Cancel',
            handler: () => {
                return new GeckoModalService().close();
            }
        }, {
            name: 'Delete',
            preset: GECKO_CONSTS.BTN_PRESET_REMOVE,
            handler: () => {
                this.deleteChangelog(changelog);

                return new GeckoModalService().close();
            }
        }];


        return new GeckoModalService().open(() => {

            let title = <Interweave content={changelog.title} />;
            return (
                <GeckoModal name="Delete Changelog" btns={btns}>
                    <div className="text-center">
                        Are you sure you want to PERMANENTLY delete the changelog <span className="font-weight-bold">{title}</span>
                    </div>
                </GeckoModal>
            );
        });
    }

    elementInfiniteLoad() {
        if (!this.state.isInfiniteLoading) {
            return null;
        }

        return (
            <div className="row justify-content-center pt-3">
                <div className="col-md-auto">
                    <Spinner />
                </div>
            </div>
        );
    }

    /* =========================

        Renders
    
    ========================= */

    /**
    *
    * Render a form to compose a new changelog
    */
    renderComposer() {
        return <Composer ref={'composer'} create={this.create} edit={this.edit} fields={this.getEditableFields()} />
    }

    /**
    *
    * Render a single changelog inner body
    * @param {data} (Object) - a single changelog
    */
    renderSingleChangelog(data) {
        let tooltipId = 'sl-' + Utils.guid();

        return (
            <div className="row">
                <div className="col">

                    <div className="row">
                        <div className="col">
                            <Interweave
                                tagName="div"
                                content={data.body}
                            />

                        </div>
                    </div>

                    <div className="row pt-3 changelogMeta justify-content-start">
                        <div className="col-12 col-md-auto text-truncate metaCol pb-2">
                            <GeckoIcon icon="calendar" /> <span id={tooltipId}>{moment.unix(data.createdAt).format('Do MMM YY')}</span>

                            <UncontrolledTooltip delay={0} placement="top" target={tooltipId}>
                                Created on {moment.unix(data.createdAt).format('MMMM Do YYYY, HH:mm:ss')}
                            </UncontrolledTooltip>
                        </div>

                        {data.link && data.link !== '' &&
                            <div className="col-12 col-md-auto text-truncate metaCol pb-2">
                                <GeckoIcon icon="link" /> <a href={data.link} target="_blank">{data.link}</a>
                            </div>
                        }

                        <div className="col-12 col-md-auto text-truncate metaCol pb-2">
                            <GeckoIcon icon="tag" /> {data.releaseType}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSingleTitle(title) {
        return <Interweave content={title} />
    }

    /**
    * Render collapsible with all the changelogs
    */
    renderCollapsible() {
        return <Collapsible sections={this.prepareSections()} />
    }

    render() {
        return (
            <div className="mainContentWrapper" style={{ paddingTop: '100px' }}>
                <div className="row mb-5">
                    <div className="col-12 col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
                        {this.renderComposer()}
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-12 col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">

                        {this.renderCollapsible()}

                        {this.elementInfiniteLoad()}

                    </div>
                </div>
            </div>
        );
    }
}

/* ============================

    Redux

============================ */

const mapStateToProps = (state) => {
    return {
        ...state,
    }
}

export default connect(mapStateToProps)(ChangelogsPage);
