import React, {Component} from 'react';
import {GECKO_CONSTS, GeckoBtnsSorted, GeckoCardTable, GeckoModalService} from "@geckolabs/gecko-react-ui";
import moment from "moment";
import { Get } from '../../../../services/ApiRequest';
import AddToQueue from "./components/AddToQueue/addToQueue";
import MarkAsFailed from "./components/MarkAsFailed/markAsFailed";

export default class ImportsExports extends Component {
    constructor(props) {
        super();

        this.state = {
            type: props.type,
            account_id: props.account_id,
            stuckItems: [],
            title: 'Incomplete ' + props.type + 's',
        };
    }

    componentDidMount() {
        this.getIncomplete();
    }

    openConfirmModal(Body, props) {
        return new GeckoModalService().open(()=>{
            return (
                <Body {...props} />
            );
        });
    }

    modalButtons = [
        {
            name: 'Cancel',
            preset: GECKO_CONSTS.BTN_PRESET_CLOSE,
            handler: () => {
                return new GeckoModalService().close();
            },
        }
    ];

    formatDate(date) {
        return moment.unix(date).format('D MMM Y hh:mm:ss');
    }

    cols() {
        return [
            {
                key: 'id',
                name: 'Id',
                class: 'block',
                sortable: true,
                render: (value) => (<a className="pointer block">{value}</a>),

            }, {
                key: 'title',
                name: 'Title',
                class: 'block',
                sortable: true,
                render: (value) => (<a className="block">{value}</a>),
            }, {
                key: 'status_title',
                name: 'Status',
                class: 'block',
                sortable: true,
                render: (value) => (<a className="block">{value}</a>),
            }, {
                key: 'created_at',
                name: 'Created At',
                class: 'block',
                sortable: true,
                render: (value) => (<a className="block">{this.formatDate(value)}</a>),
            }, {
                key: 'updated_at',
                name: 'Updated At',
                class: 'block',
                sortable: true,
                render: (value) => (<a className="block">{this.formatDate(value)}</a>),
            }, {
                key: 'action',
                name: 'Action',
                class: 'block',
                render: (value, row) => {
                    return (
                        <button className="gecko-btn-add-to queue gecko-btn gecko-ui-material btn btn-default" onClick={() => this.openConfirmModal(AddToQueue, {
                            id: row.id,
                            type: this.state.type,
                            account_id: this.state.account_id,
                            action: 'addToQueue'
                        })}>
                            <i className="gecko-icon fa fa-send" /> Add to queue
                        </button>
                    );
                },
            }, {
                key: 'action',
                name: 'Action',
                class: 'block',
                render: (value, row) => {
                    return (
                        <button className="gecko-btn-add-to queue gecko-btn gecko-ui-material btn btn-default" onClick={() => this.openConfirmModal(MarkAsFailed, {
                            id: row.id,
                            type: this.state.type,
                            account_id: this.state.account_id,
                            action: 'markAsFailed'
                        })}>
                            <i className="gecko-icon fa fa-send" /> Mark as failed
                        </button>
                    );
                },
            }
        ]
    }

    getIncomplete(page = 1){
        const _this = this;

        Get('/imports/incomplete', {
            'import_rfields': 'id,title,status_title,progress,created_at,updated_at',
            'account_id': _this.state.account_id,
            'per_page': 20,
            'page': page,
        }).then((data) => {
            _this.setState({
                stuckItems: data,
            });
        });
    }

    pagination() {
        return {
            total_items: this.state.stuckItems.total,
            perPage: this.state.stuckItems.perPage,
            current_page: this.state.stuckItems.current_page,
            total_pages: this.state.stuckItems.last_page,
            changePage: (page) => {
                this.getIncomplete(page);
            }
        };
    }

    renderItemsList() {
        return (
            <GeckoCardTable
                name={this.state.title}
                cols={this.cols()}
                rows={this.state.stuckItems.data || []}
                pagination={this.pagination()}
            />
        )
    }

    render() {
        return (
            <div className="">
                {this.renderItemsList()}

                <div className="modal-footer">
                    <GeckoBtnsSorted items={this.modalButtons} />
                </div>
            </div>
        );
    }
}
