import { TokenManager } from '../Auth';

export const getHeaders = async (method = 'GET') => {
    const tokens = await TokenManager.fromStorage();

    const headers = {};
    headers['Authorization'] = `Bearer ${tokens.AccessToken}`;

    if (method === 'POST') {
        headers['Accept'] = 'application/json';
        headers['Content-Type'] = 'application/json';
    }

    return headers;
};

export const cleanGetParams = (params={}) => {
    return Object.keys(params).reduce((result, param) => {
        return params[param] !== undefined ? {...result, [param]: params[param]} : result;
    }, {});
};

export default {
    getHeaders,
    cleanGetParams
};