// Core
import React, { Component } from 'react';
import { $state } from '../../router/';

import Collapsible from '../../components/Collapsible';
import {GECKO_CONSTS, GeckoIcon, GeckoCard, GeckoCardForm, GeckoToastService} from '@geckolabs/gecko-react-ui';
import Interweave from 'interweave';

const AWS = require('aws-sdk');
AWS.config = new AWS.Config({
    // GeckoManage-Changelog-Updater user
    accessKeyId: 'AKIAI5TMQOZSYNDLOHVQ',
    secretAccessKey: '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',
    region: 'eu-west-2'
});
const S3 = new AWS.S3({params: {Bucket: 'gecko-chat-changelogs'}});

export class ChangelogsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            creatingChangelog: false,
            newChangelog: {},
            changelogs: props.changelogs
        }
    }

    renderChangelogItem(item) {
        return (
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <Interweave
                                tagName="div"
                                content={item.content}
                            />
                            
                        </div>
                    </div>

                    <div className="row pt-3 changelogMeta justify-content-start">
                        <div className="col-12 col-md-auto text-truncate metaCol pb-2">
                            <GeckoIcon icon="tag" /> {item.release_type}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    fields() {
        return [
            {
                key: 'title',
                label: 'Title',
                type: GECKO_CONSTS.FIELD_TYPE_TEXT,
                width: '100%',
                required: true
            },
            {
                key: 'release_type',
                label: 'Release Type',
                type: GECKO_CONSTS.FIELD_TYPE_SELECT,
                width: '100%',
                optionValueKey: 'value',
                optionsLabelKey: 'label',
                required: true,
                options: [
                    {
                        label: 'General release',
                        value: 'general',
                    },
                    {
                        label: 'Feature release',
                        value: 'feature',
                    },
                ]
            },
            {
                key: 'content',
                label: 'Content',
                width: '100%',
                required: true,
                type: GECKO_CONSTS.FIELD_TYPE_TEXTAREA
            },
        ];
    }

    handleFieldChange(key, value) {
        const newChangelog = Object.assign({}, this.state.newChangelog, {[key]: value});
        this.setState({newChangelog});
    }

    addNewChangelog() {
        if(!this.state.newChangelog.title) { return new GeckoToastService().error('You must add a title for the new Changelog'); }
        if(!this.state.newChangelog.release_type) { return new GeckoToastService().error('You must add a release type for the new Changelog'); }
        if(!this.state.newChangelog.content) { return new GeckoToastService().error('You must add some content for the new Changelog'); }
        const newChangelogs = this.state.changelogs.concat(this.state.newChangelog);
        const params = {
            Body: JSON.stringify(newChangelogs),
            ContentType: 'application/json',
            Key: 'changelogs.json'
        }
        S3.putObject(params, (err, data) => {
            if(err) { return new GeckoToastService().error(err); }
            new GeckoToastService().success(`Changelog ${this.state.newChangelog.title} created`);
            this.setState({
                newChangelog: {},
                creatingChangelog: false,
                changelogs: newChangelogs
            });
        });
    }

    render() {
        const style = {
            composerCard: {
                padding: '10px',
                fontSize: '20px',
                fontWeight: '300',
                color: 'rgba(0,0,0,0.4)',
                cursor: 'text',
            }
        };

        const footerButtons = [
            {
                name: 'Cancel',
                handler: () => this.setState({creatingChangelog: false}),
            },
            {
                name: 'Save',
                type: GECKO_CONSTS.BTN_TYPE_PRIMARY,
                handler: this.addNewChangelog.bind(this),
            }
        ]

        const changelogSections = this.state.changelogs.map((item, key) => {
            return {
                title: item.title,
                render: this.renderChangelogItem(item),
                renderTitle: () => <span>{item.title}</span>,
            }
        });
        return (
            <div className="mainContentWrapper" style={{paddingTop: '100px'}}>
                <div className="row mb-5">
                    <div className="col-12 col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
                        {
                            this.state.creatingChangelog 
                            ? 
                                <GeckoCardForm 
                                    name="New Changelog"
                                    fields={this.fields()}
                                    values={this.state.newChangelog}
                                    footerBtns={footerButtons}
                                    handler={this.handleFieldChange.bind(this)}>
                                </GeckoCardForm>
                            : 
                                <GeckoCard>
                                    <div className="" style={style.composerCard} onClick={() => this.setState({creatingChangelog: true})}>
                                        Create a new changelog...
                                    </div>
                                </GeckoCard>
                        }
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-12 col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
                        <Collapsible sections={changelogSections} />
                    </div>
                </div>
            </div>
        );
    }

}

export default ChangelogsPage;