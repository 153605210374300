import { refreshAuthToken } from '../../Auth';
import executeRequest from '../executeRequest';

export const prepareComposeRequest = (getUrl, getHeaders) => {
    return async (endpoint, method = 'GET', params = {}) => {
        if (!endpoint) return Promise.reject('No endpoint provided.');
    
        // Check AccessToken, refresh if neccessary or eject if RefreshToken is expired
        await refreshAuthToken();
    
        // Build request url + headers
        const [url, headers] = await Promise.all([
            getUrl(endpoint),
            getHeaders(method)
        ]);
    
        return executeRequest(url, headers, method, params);
    };
}

export default prepareComposeRequest;