import React, { Component } from 'react';
import { Gecko } from '../../Logic';
import { router, $state, $stateParams } from '../../router/';

import './drawer.css';

import { UISref } from '@uirouter/react';

export default class AppSidebar extends Component {
    items() {
        return [
            {
                name: 'Dashboard',
                href: 'dashboard',
            },{
                name: 'Accounts',
                href: 'accounts',
            },{
                name: 'Create New Account',
                href: 'new-form-account',
            },{
                name: 'Form Changelogs',
                href: 'changelogs-form',
            },{
                name: 'Chat Changelogs',
                href: 'changelogs-chat',
            }
        ]
    }
    
    renderMenuItems() {
        return this.items().map((item, key) => {
            return(
                <UISref key={key} to={item.href}>
                    <a className={'mdc-list-item ' + this.getMenuItemClass(item)}>
                        {item.name}
                    </a>
                </UISref>
            )
        }); 
    }


    getMenuItemClass(item){
        if($state.current.name == item.href) {
            return 'mdc-list-item--activated';
        } 

        return null;
    }

    render() {
        return(
            <div>
                <div className="mdc-drawer__header">
                    <a href="/" className="mdc-drawer__header-content">
                        <img src="/img/gecko-logo.svg" />
                    </a>
                </div>
            
                <nav id="icon-with-text-demo" className="mdc-drawer__content mdc-list">
                    {this.renderMenuItems()}
                </nav>
            </div>
        )
    }
}