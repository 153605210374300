import React, {Component} from 'react';
import {GeckoModal} from "@geckolabs/gecko-react-ui";
import {buildModalButtons} from "../../../../../../Logic/ImportExport";

export default class MarkAsFailed extends Component {
    constructor(props) {
        super();
        this.action = 'markAsFailed';

        this.state = {
            id: props.id,
            type: props.type,
            accountId: props.account_id,
            name: 'Mark ' + props.type + ' as failed',
        };
    }

    render() {
        return (
            <GeckoModal name={this.state.name} btns={buildModalButtons(this.state, this.action)}>
                <div className="text-center">
                    Are you sure you want to mark {this.state.type} <span className="font-weight-bold">{this.state.id}</span> as failed?
                </div>
            </GeckoModal>
        );
    }
}
