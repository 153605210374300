// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { $state, $stateParams } from '../../router/';

// Redux
import { Provider, connect } from 'react-redux';

// Style
import './dashboard.css';


// Logic
import AccountLogic from '../../Logic/Account';

// Components
import { 
    Gecko,
} from '../../Logic';


export class DashboardPage extends Component {

    constructor(props) {
        super();

        this.state = {
            search: "",
            autocomplete: "",
            label: "Type to search...",
            usedLabels: [],
            show: true,
            defaultLabel: this.labelSource()[0],
        }

        // Binds
        this.onSearch = this.onSearch.bind(this);
        this.getSearchSuggestion = this.getSearchSuggestion.bind(this);
        this.renderSearch = this.renderSearch.bind(this);
        this.handleKeyboardActions = this.handleKeyboardActions.bind(this);
        this.fillSearchTermWithSuggestion = this.fillSearchTermWithSuggestion.bind(this);
        this.parseSearchTerm = this.parseSearchTerm.bind(this);
        this.goTo = this.goTo.bind(this);
        this.changePlaceholderText = this.changePlaceholderText.bind(this);
        this.parseAccountName = this.parseAccountName.bind(this);
        this.parseAccountId = this.parseAccountId.bind(this);

    }


    componentDidMount() {

        this.timer = setInterval(this.changePlaceholderText, 8000);

        if(this.props.accounts.length == 0) {
            AccountLogic.get();
        }

    }

    componentWillUnmount(){

        clearInterval(this.timer);

    }


    source() {
        let basic = ['Accounts', 'Changelog'];

        let accountNames = this.props.accounts.map((account, i) => {
            return account.name;
        });

        let accountIds = this.props.accounts.map((account, i) => {
            return account.id.toString();
        });

        let expanded = basic.concat(accountNames, accountIds);

        return expanded;
    }

    changePlaceholderText() {
        let next = this.getNextPlaceholder();

        if(typeof next == 'string') {
            this.setState({
                label: next,
            });
        } else {
            this.setState({
                label: next[0],
                usedLabels: next[1],
            });
        }
        
    }

    getNextPlaceholder() {
        let data = this.labelSource();

        if(this.state.label !== data[0]) {
            return data[0];
        }

        let nextLabels = data.filter((val, key) => {
            if(!this.state.usedLabels.includes(val)) {
                return val;
            }
        })

        let next = nextLabels[Math.floor(Math.random() * nextLabels.length)];

        let nowUsed = this.state.usedLabels.concat([next]);

        if(nowUsed.length >= data.length) {
            nowUsed = [];
        }

        return [next, nowUsed];
    }

    labelSource() {
        return [
            'Type to search...',
            'Try typing account/281...',
            'Try searching for changelog...',
            'Try searching for accounts...',
        ];
    }

    onSearch(e) {

        let suggestion = (e.target.value != '' ? this.getSearchSuggestion(e.target.value) : '');
        this.setState({
            search: e.target.value.toLowerCase(),
            autocomplete: suggestion,
        });
    }

    getSearchSuggestion(term) {
        let data = this.source();

        // Get possible results
        let possibles =  this.filter(data, term);

        return this.getLiklyResult(possibles, term);

    }

    getLiklyResult(possibles = [], term = '') {
        let likly = possibles.filter((val, key) => {

            if(val.toLowerCase().startsWith(term.toLowerCase())) {
                return val;
            }
        });

        if(likly.length > 0) {
            return likly[0].toLowerCase();
        }

        return '';
    }


    filter(data, term) {
        if(term == '') {
            return data;
        }

        return data.filter((val, key) => {
            if(val && val.search(new RegExp(term, "i")) < 0) {
            } else {
                return val.toLowerCase();
            }
        })
    }


    handleKeyboardActions(e) {
        let key = e.key;

        switch(key) {
            case 'Enter':
                this.parseSearchTerm();

                break;
            case 'ArrowRight':
                this.fillSearchTermWithSuggestion();
                break;

            default:
                 break;
        }
    }


    parseSearchTerm(){
        let term = this.state.search;

        let parsers = [this.parseAccountName, this.parseAccountId, this.parseAccount, this.parseChangelog];
        let action = null;
        for (var i = 0; i < parsers.length; i++) { 
            
            // execute the parser
            action = parsers[i](term);
            
            // If it passed then stop the loop
            if(action) {
                break;
            }
        }

        if(action) {
            if(typeof action == 'string') {
                var route = action;
            } else {
                var route = action.route;
            }

            let params = action.params || null;

            if(route) {
                this.goTo(route, params);
            }
        }
    }

    parseAccountId(term) {
        let likelyAccount = this.props.accounts.find((account, i) => {
            return account.id == term
        })

        if(!likelyAccount) {
            return null;
        }

        // navigat to account single passing in the account id
        return {route: 'account', params: {accountId: likelyAccount.id}};
    }

    parseAccountName(term) {
        let likelyAccount = this.props.accounts.find((account, i) => {
            return account.name.toLowerCase() == term
        })

        if(!likelyAccount) {
            return null;
        }

        // navigat to account single passing in the account id
        return {route: 'account', params: {accountId: likelyAccount.id}};
    }

    parseAccount(term) {
        const starts = 'account';

        if(!term.startsWith(starts)) {
            return null;
        }

        // Get parts of command
        let parts = term.split('/');

        // If there is no account id then go to accounts
        if(parts.length == 1) {
            return 'accounts';
        }  

        // navigat to account single passing in the account id
        return {route: 'account', params: {accountId: parts[1]}};
    }


    parseChangelog(term) {
        const starts = 'changelog';

        if(!term.startsWith(starts)) {
            return null;
        }

        // navigat to account single passing in the account id
        return 'changelogs-form';
    }


    fillSearchTermWithSuggestion() {
        let {search, autocomplete} = this.state;
        if(autocomplete && search && autocomplete !== '' && autocomplete !== search) {
            this.setState({
                search: autocomplete,
            });
        }
    }


    renderSearch() {
        return (
            <div id="spotlight_wrapper" className="">
                <input 
                    id="spotlight" 
                    className="moduleSearch autocomplete shared" 
                    disabled={true}
                    value={this.state.autocomplete} />

                <input 
                    name="spotlight"
                    className="main shared"
                    autoFocus="true" 
                    value={this.state.search}
                    onChange={this.onSearch}
                    onKeyDown={this.handleKeyboardActions} />

                {this.state.search == '' &&
                    <label htmlFor="spotlight">{this.state.label}</label>
                }

            </div>
        );
    }

    goTo(route, params = {}) {
        const {stateService} = this.props.transition.router;
        stateService.go(route, params);
    }

    render() {
        return (
            <div className="mainContentWrapper p-2--sm p-5 dashboardWrapper d-flex align-items-center justify-content-center">
                <div className="" style={{width: '55%'}}>
                    <div className="row pb-5">
                        <img src="/img/gecko-logo.svg" className="mx-auto" style={{width: '40%'}} />
                    </div>
                    <div  className="row">
                        <div className="col-sm-12">
                            {this.renderSearch()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}


DashboardPage.defaultProps = {
    accounts: [],
}


/* ============================

    Redux

============================ */

const mapStateToProps = (state) => {
    return {
        ...state,
    }
}


export default connect(mapStateToProps)(DashboardPage);

