// Core
import React, { Component } from 'react';
import { UISref } from '@uirouter/react';

// Style
import './Account.css';

// Logic
import AccountLogic from '../../Logic/Account';

// Services
import { Get, Post } from '../../services/ApiRequest';

// Components
import { 
    GECKO_CONSTS, 
    GeckoCardTable, 
    GeckoCard, 
    GeckoAlert, 
    GeckoToastService, 
    GeckoLoadingService, 
    GeckoModalService,
    GeckoBtn, 
    GeckoField, 
    GeckoFields,
    GeckoIcon,
    GeckoModal
} from '@geckolabs/gecko-react-ui';
import { UncontrolledTooltip } from 'reactstrap';
import Modal from '../../components/Modal';
import VoipTopUp from './components/VoipTopUp/';

// Other
import Utils from '../../Utils';
import ImportsExports from "./components/ImportsExports";
import MigrateAppProfiles from "./components/MigrateAppProfiles";
import moment from "moment";


export default class AccountPage extends Component {

    constructor(props) {
        super();
        this.AccountLogic = new AccountLogic();

        this.state = this.generateStateFromProps(props);

        // Binds
        this.generateStateFromProps = this.generateStateFromProps.bind(this);
        this.getProfileSections = this.getProfileSections.bind(this);
        this.renderProfileSections = this.renderProfileSections.bind(this);
        this.renderSection = this.renderSection.bind(this);
        this.renderSectionHeader = this.renderSectionHeader.bind(this);
        this.renderFeatureToggles = this.renderFeatureToggles.bind(this);
        this.renderLimits = this.renderLimits.bind(this);
        this.renderFeatureSection = this.renderFeatureSection.bind(this);
        this.renderLimitsSection = this.renderLimitsSection.bind(this);
        this.renderHeroSection = this.renderHeroSection.bind(this);
        this.renderLimitsModal = this.renderLimitsModal.bind(this);
        this.renderModuleSearch = this.renderModuleSearch.bind(this);
        this.openLimitsModal = this.openLimitsModal.bind(this);
        this.save = this.save.bind(this);
        this.handleFeatureChange = this.handleFeatureChange.bind(this);
        this.storeLimit = this.storeLimit.bind(this);
        this.savePackage = this.savePackage.bind(this);
        this.renderActionsSection = this.renderActionsSection.bind(this);
        this.renderPackageSection = this.renderPackageSection.bind(this);
        this.getAllPossibleFeatures = this.getAllPossibleFeatures.bind(this);

    }

    // This is all the features from the "/packages/features" call
    // defaulted to false
    defaultFeatures = {};

    /**
     * This fixes the issue where the component was not remounted after page
     * transitions. This should not be a permanent solutions and should be refactored
     *
     */
     generateStateFromProps(props) {
        return {
            moduleSearch: '',
            accountInstance: props.data.account,
            package: props.package ,
            features: this.AccountLogic.buildFeatureToggles(props.package),
            featureValues: {},
            limits: this.AccountLogic.buildLimitInputs(props.limits),
            limitValues: {},
            packageId: null,
            descriptions: {},
            transaction: {},
        };
     }

     componentDidUpdate(prevProps) {
        if(this.props != prevProps) {
            this.setState(this.generateStateFromProps(this.props));
        }
     }


    componentDidMount() {
        const _this = this;

        Get('/packages', {}).then((data) => {
            _this.setState({
                packages: data
            });
        });

        Get('/packages/features', {}).then((data) => {
            let flatList = Utils.flatten(data.features);

            _this.setState({
                descriptions: flatList,
            });

            this.defaultFeatures = flatList;

            this.setState({
                features: this.getAllPossibleFeatures(this.props.package, this.defaultFeatures),
            });

        });
    }

    arrayToObj(data = []) {
        return data.reduce((accumulator, currentValue) => {
            return Object.assign({}, accumulator, {[currentValue.key]: currentValue});
        }, {});
    }


    getAllPossibleFeatures(accountValues, defaultValues) {
        let props = this.AccountLogic.buildFeatureToggles(accountValues);
        let defaults = this.AccountLogic.buildFeatureToggles(defaultValues);

        // Created indexes for easy merging
        let propsIndex = this.arrayToObj(props);
        let defaultsIndex = this.arrayToObj(defaults);

        let master = Object.assign({}, defaultsIndex, propsIndex);
        let merge = Object.values(master);

        return merge;
    }


    /* =========================

        Global component methods
    
    ========================= */

    /**
    *
    * All field values can be overridden in the state, this will return the override or a default
    * @param {state} (Object) - the state object that you are looking for
    * @param {dafualtVal} (Mixed) - the default value to return if the state override doesnt exist
    */
    getStateOverride(state, defaultVal) {
        if(typeof state != 'undefined'){
            return state;
        }

        return defaultVal
    }


    /**
    *
    * Gather all the changes and persist them to the database
    */
    save() {
        const _this = this;

        let account = {};

        // // Clean the limit object
        let limitValues = {};
        for (var limit in this.state.limitValues) {
            limitValues[this.cleanLimitKey(limit)] = this.state.limitValues[limit];
        }

        // // Merge the changed features and limits into an object
        let features = Object.assign({}, this.state.featureValues, limitValues);

        // // set the new features on the instance
        account.features = features;

        account.include = 'package,limits';

        if(this.state.packageId) {
            try {
                account.package_id = parseInt(this.state.packageId);
            } catch (err) {
                console.log('couldnt set account package: ', err);
            }
        }

        Post('/accounts/' + this.props.accountId, account)
        .then((resp) => {
            new GeckoToastService().success('Account saved successfully.');            
        }).catch((err) => {
            console.log(err);
            new GeckoToastService().error(err);
        });
    }

    removeLimit() {

    }


    /**
    *
    * The limit form field keys are suffixed with [allowed, used, available] we need to remove this
    * @param {key} (String) - The form field key to be cleaned
    * @returns (String) - The cleaned string
    */
    cleanLimitKey(key) {
        const separator = "_";

        // Separate the string into an array
        let parts = key.split(separator);

        // Remove the last element as we dont want it
        parts.pop();

        // Re-create a string from the remaining parts
        return parts.join(separator);
    }


    openLimitsModal(limit) {

        return new GeckoModalService().open(()=>{
            return this.renderLimitsModal(limit);
        });
    }

    openSingleUseModal(field, save) {
        return new GeckoModalService().open(()=>{
            return this.renderSingleUseModal(field, save);
        });
    }

    openCustomModal(body, props = {}) {
        return new GeckoModalService().open(()=>{
            return this.renderCustomModal(body, props);
        });
    }

    openCustomLargeModal(body, props = {}) {
        return new GeckoModalService().open(()=>{
            return this.renderCustomModal(body, props);
        }, {
            size: 'xl',
        });
    }


    /* =========================

        Models
    
    ========================= */

    getProfileSections(){
        return [
            {
                title: 'About',
                renderSection: this.renderHeroSection,

            }, {
                title: 'Search',
                render: this.renderModuleSearch,
                renderSectionHeader: null,

            }, {
                title: 'Limits',
                render: this.renderLimitsSection,
                limits: this.state.limits,

            }, {
                title: 'Features',
                render: this.renderFeatureSection.bind(this),
                features: this.state.features,
            }, {
                title: 'Package',
                render: this.renderPackageSection,
                // features: this.state.features,
            }, {
                title: 'Actions',
                render: this.renderActionsSection,
                // features: this.state.features,
            }, {
                title: 'Misc',
                render: () => {
                    return (
                        <MigrateAppProfiles 
                            accountId={this.props.accountId}
                            accountUuid={this.props.account.uuid}
                            accountName={this.props.account.name}
                            accountIsNewEngageWebApp={this.props.account.package.new_engage_web_app}
                        />
                    );                    
                }  
            }          
        ];
    }


    /* =========================

        Render Helpers
    
    ========================= */

    /**
    *
    * Update the state override for limits and call the save method once the user closes the limit modal and save remotely
    */
    storeLimit(key, val) {

        // Close the modal
        new GeckoModalService().close();

        if(!val || val == '') {
            val = null;
        }

        if(key) {
            let current = this.state.limitValues;
            let next = Object.assign({}, current, {[key]: val});

            this.setState({
                limitValues: next,
            }, this.save);
        }
    }

    savePackage(key, val) {

        // Close the modal
        new GeckoModalService().close();

        if(val) {
            this.setState({
                packageId: val,
            }, this.save);
        }
    }


    /**
    *
    * Update the state for the feature toggle and save remotely
    * @param {key} (String) - field key
    * @param {val} (String) - value to set
    */
    handleFeatureChange(key, val) {
        let current = this.state.featureValues;
        let next = Object.assign({}, current, {[key]: val});

        this.setState({
            featureValues: next,
        }, this.save);
    }


    filter(data) {
        let searchTerm = this.state.moduleSearch;

        if(searchTerm == '') {
            return data;
        }

        return data.filter((val, key) => {
            if(val.searchTags && val.searchTags.search(new RegExp(searchTerm, "i")) < 0) {
                
            } else {
                return val;
            }
        })
    }



    /* =========================

        Renders
    
    ========================= */

    renderFeatureDescription(desc = '', id) {
        return (
            <div id={id} className="feature-toggle-description">
                <GeckoIcon icon="info-circle" />
                <UncontrolledTooltip delay={0} placement="top" target={id}>
                    {desc}
                </UncontrolledTooltip>
            </div>
        )
    }

    /**
    *
    * Default if a section cant be rendered
    *
    */
    renderModuleSearch() {
        return (
            <div className="row placeholder no-gutters">
                <div className="col">
                    <GeckoCard>
                        <input className="moduleSearch" autoFocus="true" placeholder="Type to search..." value={this.state.moduleSearch} onChange={(val) => this.setState({moduleSearch: val.target.value})}/>
                    </GeckoCard>
                </div>
            </div>
        )
    }


    /**
    *
    * Default if a section cant be rendered
    *
    */
    renderSectionNotFound() {
        return (
            <div className="row placeholder">
                <div className="col">
                    No data
                </div>
            </div>
        )
    }


    /**
    *
    * Gets all the section data and renders each section
    *
    */
    renderProfileSections(){
        let data = this.getProfileSections();

        return data.map((section, key) => (section.renderSection ? section.renderSection(section, key) : this.renderSection(section, key)));
    }


    /**
    *
    * Renders an individual account section
    * @param {section} (OBJ) - Object containing the section data
    */
    renderSection(section, key) {
        return (
            <div key={key} >
                <div className="row">
                    <div className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">

                        {this.renderSectionHeader(section)}

                        {section.render(section)}
                     </div>
                </div>
            </div>
        )
    }


    /**
    *
    * Renders a section header
    * @param {options} (Obj) - Header data, usually just a label
    */
    renderSectionHeader(section = {}) {

        // Override the section header if its passed in
        if((section.renderSectionHeader || section.renderSectionHeader == null) && typeof section.renderSectionHeader !== 'undefined') {
            return section.renderSectionHeader
        }

        return (
            <div className="row pb-1 pl-xs-3">
                <div className="col smallHeader">
                    {section.title || 'Section'}
                </div>
            </div>
        )
    }


    /**
    *
    * Renders a list of UI toggles for each feature
    * @param {data} (Array) - array of fields to be used in GeckoFields
    */
    renderFeatureToggles(data = []) {
        if(data.length > 0) {
            return data.map((val, key) => {

                if (val.key === 'new_engage_web_app') return null;

                // If there is a state override then use that as the value otherwise used the object
                // We need to check for undefined as the value could be == (bool)false
                let fieldValue = {[val.key]: this.getStateOverride(this.state.featureValues[val.key], val.val)};
                let desc = null;

                if(this.state.descriptions[val.key]) {
                    desc = this.renderFeatureDescription(this.state.descriptions[val.key], val.key + '_description');
                }

                return (
                    <li key={key} className="list-group-item">
                        <div className="row">
                            <div className="col-9 col-sm-7 col-md-8 col-lg-9 d-flex align-items-center">
                                {val.label} {desc}
                            </div>

                            <div className="col-3 col-sm-5 col-md-4 col-lg-3 d-flex justify-content-end liteToggleWrapper">

                                <GeckoFields fields={[val]} values={fieldValue}  handler={this.handleFeatureChange} />
                            </div>


                        </div>

                    </li>
                )
            });
        }

        return this.renderSectionNotFound();
    }




    renderActions(data = []) {
        if(data.length > 0) {
            return data.map((val, key) => {
                let press = () => {};

                if(val.onClick) {
                    press = val.onClick;
                }

                return (
                    <li key={key} className="list-group-item">
                        <div className="row pointer" onClick={press}>
                            <div className="col-9 col-sm-7 col-md-8 col-lg-9 d-flex align-items-center">
                                {val.title}
                            </div>

                            <div className="col-3 col-sm-5 col-md-4 col-lg-3 d-flex align-items-center justify-content-end">
                                <GeckoIcon icon="angle-right" />
                            </div>


                        </div>

                    </li>
                )
            });
        }

        return this.renderSectionNotFound();
    }

    renderLimits(data = []) {
        if(data.length > 0) {
            return data.map((val, key) => {

                let allowed = this.getStateOverride(this.state.limitValues[val.allowed.key], val.allowed.val);
                let used = val.used.val || 0;
                
                return (
                    <div className="col-6 col-sm-6 col-md-4 mb-3 center" key={key}>
                        <GeckoCard>
                            <div className="pointer">
                                <div className="p-3" onClick={this.openLimitsModal.bind(this, val)}>
                                    <div className="row limitCard">
                                        <div className="col d-flex justify-content-center">
                                            <span className="limitCardLabel swatch-darken-6">
                                                {val.allowed.label}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row limitCardValue swatch-darken-7">
                                        <div className="col d-flex justify-content-center">
                                            {Utils.numberWithCommas(used) || 0} <span> / {allowed || <span>&infin;</span>}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </GeckoCard>
                    </div>
                )
            });
        }

        return this.renderSectionNotFound();
    }

    
    /**
    *
    * Renders a modal that lets the user update the limit value
    * @param {limit} (Object) - the single field object
    */
    renderLimitsModal(limit) {
        // Set a placeholder when there is no limit
        // NOTE:: this isnt supported in UI Kit yet
        if(!limit.allowed.config){
            limit.allowed.config = {};
        }

        limit.allowed.config.placeholder = "Unlimited";

        // Prepare the input value
        let values = {[limit.allowed.key]: limit.allowed.val};

        return (
            <Modal
                fieldKey={limit.allowed.key}
                value={limit.allowed.val}
                fields={[limit.allowed]} 
                store={this.storeLimit}
                 /> 
        );
    }


    /**
    *
    * @param {limit} (Object) - the single field object
    */
    renderSingleUseModal(field, save) {
        let fields = field;

        if(Array.isArray(field)) {
            fields = field;
        } else {
            fields = [field];
        }

        return (
            <Modal
                fieldKey={field.key}
                value={field.val}
                fields={fields} 
                store={save} /> 
        );
    }

    /**
    *
    */
    renderCustomModal(Body, props = {}) {
        return (
            <GeckoModal>
                <Body {...props} />
            </GeckoModal> 
        );
    }


    renderHeroSection(section = {}, key) {

        let account = this.props.account;
        let accountPackage = this.state.package;

        let accountColor = account.color;
        let inverseAccountColor = Utils.getContrastColor(account.color);

        return (
            <div key={key} className="p-5 mb-5 heroSection" style={{backgroundColor: account.color, color: inverseAccountColor}}>
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col accountName d-flex justify-content-center">
                                {account.name}
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col accountPackage d-flex justify-content-center">
                                <span style={{backgroundColor:inverseAccountColor, color: accountColor}}>{accountPackage.title}</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col d-flex justify-content-center">
                                <span className="mx-2">
                                    <span className="currencySymbol">{Utils.decodeHtmlEntity(account.currency_symbol)}</span> {account.balance}
                                </span>
                                <div id="createdAtTooltip" className="mx-2" style={{display: 'inline-block'}}>
                                    <GeckoIcon icon="calendar" /> {moment.unix(account.created_at).format('MMMM Do YYYY')}
                                </div>
                                <UncontrolledTooltip delay={0} placement="top" target="createdAtTooltip">
                                    {moment.unix(account.created_at).format('MMMM Do YYYY, HH:mm:ss')}
                                </UncontrolledTooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        )
    }


    renderFeatureSection(section = {}) {        
        let data = this.filter(section.features);

        return (
            <div className="row">
                <div className="col">
                    <GeckoCard>
                        <ul className="list-group list-group-flush">
                            {this.renderFeatureToggles(data)}
                        </ul>
                    </GeckoCard>
                </div>
            </div>  
        )
    }


    renderLimitsSection(section = {}) {

        let data = this.filter(section.limits);

        return(
            <div className="row">
                {this.renderLimits(data)}
            </div>
        );
    }


    renderActionsSection(section = {}) {
        let actionList = [
            {
                title: 'Top Up VoIP Credit',
                onClick: () => this.openCustomModal(VoipTopUp, {
                    account_uuid: this.state.accountInstance.uuid,
                    balance: this.props.account.balance,
                    currency: Utils.decodeHtmlEntity(this.props.account.currency_symbol)
                })
            },
            {
                title: 'Imports',
                onClick: () => this.openCustomLargeModal(ImportsExports, {
                    type: 'import',
                    account_uuid: this.state.accountInstance.uuid,
                    account_id: this.props.accountId,
                    stuckItems: this.state.stuckImports,
                })
            }
        ];

        return(
            <div className="row">
                <div className="col">
                    <GeckoCard>
                        <ul className="list-group list-group-flush">
                            {this.renderActions(actionList)}
                        </ul>
                    </GeckoCard>
                </div>
            </div> 
        );
    }


    renderPackageSection(section = {}) {
        let ops = [];

        if(this.state.packages) {
            ops = this.state.packages.map((pack, key) => {
                return {
                    value: pack.id,
                    label: pack.title,
                }
            })

        }

        let field = {
            key: 'package_id',
            val: this.state.package.id,
            label: 'Release Type',
            type: GECKO_CONSTS.FIELD_TYPE_SELECT,
            width: '100%',
            optionValueKey: 'value',
            optionsLabelKey: 'label',
            required: true,
            options: ops,
        }

        // let data = this.filter(section.limits);
        let actionList = [
            {
                title: this.state.package.title,
                onClick: () => this.openSingleUseModal(field, this.savePackage)
            }
        ];

        return(
            <div className="row">
                <div className="col">
                    <GeckoCard>
                        <ul className="list-group list-group-flush">
                            {this.renderActions(actionList)}
                        </ul>
                    </GeckoCard>
                </div>
            </div> 
        );
    }


    render() {
        return (
            <div className="mainContentWrapper">

                {this.renderProfileSections()}
                
            </div>
        );
    }

}