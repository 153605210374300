
// Components
import AccountsPage from './accounts';

// Logic
import AccountLogic from '../../Logic/Account';

// Services
import Utils from '../../Utils';


export default {
    name: 'accounts',
    url: '/accounts/?{page}&{keyword}',
    component: AccountsPage,
    resolve: [
        {
            token: 'data',
            deps: ['$transition$'],
            resolveFn: (trans) => {
                let params = {};

                if(trans.params().keyword){
                    params.keyword = decodeURIComponent(trans.params().keyword);
                }

                if(trans.params().page){
                    params.page = trans.params().page;
                }

                return AccountLogic.get(true, params);
            }
        },
        {   
            token: 'accounts',
            deps: ['data'],
            resolveFn: (data) => data.data
        },{   
            token: 'pagination',
            deps: ['data'],
            resolveFn: (data) => Utils.pick(data, ['current_page', 'first_page_url', 'last_page', 'last_page_url', 'per_page', 'from', 'to', 'total']),
        },
    ]
};