// Core
import React, { Component } from 'react';

// Components
import {     
    GeckoModal
} from '@geckolabs/gecko-react-ui';


export default class Modal extends Component {

    constructor(props) {
        super();

        this.state = {
            fieldKey: props.fieldKey,
            value: props.value,
        };

        // Binds
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            fieldKey: nextProps.fieldKey,
            value: nextProps.value,
        });
    }


    /**
    *
    * Update the state for the input value on the limits modal as the user types
    * @param {key} (String) - field key
    * @param {val} (String) - value to set
    */
    handleChange(key, val) {
        this.setState({
            value: val,
        });
    }


    /**
    *
    * Call the passed in store() method passing
    */
    store() {
        if(this.props.store) {
            this.props.store(this.state.fieldKey, this.state.value);
        }
    }

    render() {
        // Create buttons for the modal
        let footerBtns = [{
            name: 'Save',
            handler: this.store.bind(this),
        }];

        return (
            <GeckoModal
                {...this.props}
                values={{[this.state.fieldKey]: this.state.value}}
                btns={footerBtns}
                handler={this.handleChange.bind(this)}  /> 
        );
    }
}
