/**
 * Constants
 */

export const OVERWRITE_CHANGELOGS = 'changelogs/OVERWRITE_CHANGELOGS';
export const ADD_CHANGELOGS = 'changelogs/ADD_CHANGELOGS';
export const UPDATE_CHANGELOG = 'changelogs/UPDATE_CHANGELOG';
export const DELETE_CHANGELOG = 'changelogs/DELETE_CHANGELOG';


/**
 * Action Creators
 */
export function overwriteChangelogs(data) {
    return { type: OVERWRITE_CHANGELOGS, payload: data }; 
}

export function addChangelogs(data) {
    return { type: ADD_CHANGELOGS, payload: data }; 
}

export function updateChangelog(data) {
    return { type: UPDATE_CHANGELOG, payload: data }; 
}

export function deleteChangelog(id) {
    return { type: DELETE_CHANGELOG, payload: id }; 
}


/**
 * Reducer
 */
const initialState = []

export default function changelogs(state = initialState, action) {
    switch (action.type) {
        
        case OVERWRITE_CHANGELOGS:
            // Overwrite everything with the payload (fresh fetched data)
            return action.payload;
        
        case UPDATE_CHANGELOG:
            if(action.payload){
                let logFound = false;
                const newLogs = state.map(log => {
                    if(log.id === action.payload.id) {
                        logFound = true;
                        return action.payload;
                    }
                    return log;
                });
                if(!logFound) { return newLogs.concat(action.payload) }

                return newLogs;
            }

            return state;

        case ADD_CHANGELOGS:
            return state.concat(action.payload);
        
        case DELETE_CHANGELOG:
        	return state.filter(c => c.id !== action.payload)
        
        default:
            return state
    }
}