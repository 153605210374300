import router from './index';

// States
import fourofour from '../views/404/state';
import DashboardPage from '../views/accounts/state';
import AccountPage from '../views/account/state';
import AccountsPage from '../views/dashboard/state';
import FormChangelogsPage from '../views/form-changelogs/state';
import ChatChangelogsPage from '../views/chat-changelogs/state';
import NewFormAccount from '../views/new-form-account/state';
import NewChatAccount from '../views/new-chat-account/state';

const states = [
    fourofour,
    DashboardPage,
    AccountsPage,
    AccountPage,
    FormChangelogsPage,
    ChatChangelogsPage,
    NewFormAccount,
    NewChatAccount,
];

// Register states
states.forEach(state => router.stateRegistry.register(state));

export default router;