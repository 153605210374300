import GeckoAuthSdk from '@geckolabs/gecko-auth-sdk';
import LocalStorage from '@geckolabs/gecko-auth-local-storage-adapter';
import Env from '../Env';

const AUTH_API_URL = Env('REACT_APP_AUTH_API_URL');
const AUTH_APP_URL = Env('REACT_APP_AUTH_APP_URL');

const instance = GeckoAuthSdk({ 
    baseUrl: AUTH_API_URL,
    storage: LocalStorage
});

const TokenManager = instance.TokenManager;
const AuthRequest  = instance.AuthRequest;

// Set From Env
AuthRequest.getAuthUrl         = () => `${AUTH_APP_URL}`;
AuthRequest.getAuthRedirectUrl = () => `${AUTH_APP_URL}/logout/`;

let inflightRefresh = null;

const refreshAuthToken = async () => {
    // Dont refresh if refresh is already underway
    if (!inflightRefresh) {
        inflightRefresh = TokenManager.refreshToken().catch(err => {
            // Cant refresh AccessToken, return to login page
            window.location.href = AuthRequest.getAuthRedirectUrl();
        }).finally(() => {
            inflightRefresh = null;
        });
    }

    // Pause while inflight refresh completes
    await inflightRefresh;

    return Promise.resolve();
};

const getClaimCode = () => {
    let vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
        vars[key] = value;
    });
    return vars['code'];
};

export {
    TokenManager,
    AuthRequest,
    refreshAuthToken,
    getClaimCode
};

export default instance;