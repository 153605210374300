// Core
import React, { Component } from 'react';

// Style
import './collapsible.css';

// Components
import Section from './Section';


export default class Collapsible extends Component {

	constructor(props) {
		super();

		this.state = {
			sections: props.sections,
		}

		// Binds
		this.renderSections = this.renderSections.bind(this);
		this.renderFailedState = this.renderFailedState.bind(this);
	}


	/**
    *
    * Update the state with any section data passed in from the parent
    */
	componentWillReceiveProps(nextProps) {
		this.setState({
			sections: nextProps.sections,
		});
	}


	/* =========================

        Renders
    
    ========================= */

    /**
    *
    * Render all sections
    * TODO:: perhaps this should accept and array instead
    */
	renderSections() {

		// If we have bad data then abandon and render the error component
		if(!this.state.sections || !Array.isArray(this.state.sections)) {
			return this.renderFailedState();
		}

		// Return each section in a component
		return this.state.sections.map((section, key) => {
			let title = section.title || '';
			let render = section.render || null;
			let renderTitle = section.renderTitle || null;
			let buttonArray = section.buttonArray || null;

			return (
				<Section key={key} title={title} renderTitle={renderTitle} buttonArray={buttonArray}>
        			{render}
        		</Section>
			)
		})
	}


	/**
    *
    * Render an error component if something fails
    */
	renderFailedState() {
		// If a custom error component is passed in use that
		if(this.props.failed) {
			return this.props.failed();
		}

		return null;
	}


  	render() {
    	return (
      		<div className="gecko-collapsible-container">
				{this.renderSections()}        		
      		</div>
    	);
  	}
}