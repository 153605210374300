import './app-footer.css';
import React, { Component } from 'react';

const data = require('../../../package.json');

export class AppFooter extends Component {
    render() {
    	return null;
    	return (
    		<div className="app-footer text-center text-muted">
    			Version {data.version}
    		</div>
    	);
    }
}

export default AppFooter;