// Redux
import {combineReducers} from 'redux'

// Modules
import app from './app'
import accounts from './accounts';
import changelogs from './changelogs';

const appReducer = combineReducers({
    app,
    // auth,
    accounts,
    changelogs,
})  


const rootReducer = (state, action) => {
    if (action.type === 'DESTROY') {
        state = undefined
    }

    return appReducer(state, action)
}

export default rootReducer
