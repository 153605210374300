import ChangelogsPage from './changelogs';
import { overwriteChangelogs } from '../../modules/changelogs';
import GeckoStore from '../../services/GeckoStore';
import { ChangelogRepository } from '../../Gecko/Changelogs';

export default {
    name: 'changelogs-form',
    url: '/changelogs/form',
    component: ChangelogsPage,
    resolve: [
        {   
            token: 'changelogs',
            resolveFn: (data) => {
                return ChangelogRepository.getPage().then((response) => {
                    GeckoStore.redux.dispatch(overwriteChangelogs(response.data));
                    return response;
                });
            }
        },
        {   
            token: 'pagination',
            deps:['changelogs'],
            resolveFn: ({pagination}) => Promise.resolve(pagination)
        },
    ]
};
