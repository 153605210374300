// Services
// import GeckoStore from '../services/GeckoStore';

// Logic
// import TagLogic from '../Logic/TagLogic';


/**
 * Constants
 */

export const SET_LOGGED_IN_USER = 'app/SET_LOGGED_IN_USER';;
export const DELETE_LOGGED_IN_USER = 'app/DELETE_LOGGED_IN_USER';
export const DESTROY = 'DESTROY';


/**
 * Action Creators
 */
export function setLoggedInUser (user){
    return async (dispatch, getState) => {
        console.log('about to dispatch setLoggedInUser');
        dispatch({ type: SET_LOGGED_IN_USER, payload: user });
    }
}

export function deleteLoggedInUser (user){
    return async (dispatch, getState) => {
        dispatch({ type: SET_LOGGED_IN_USER, payload: "" });
    }
}

export function destroy (){
    console.log('DESTROY ing the app from .app.js');
    return async (dispatch, getState) => {
        dispatch({ type: DESTROY, payload: null });
    }
}


/**
 * Reducer
 */
const initialState = {}

export default function app(state = initialState, action) {
    switch (action.type) {
        
        case SET_LOGGED_IN_USER:
            return Object.assign({}, state, {loggedInUser: action.payload});
        
        case DELETE_LOGGED_IN_USER:
            return Object.assign({}, state, {loggedInUser: action.payload});
        
        case DESTROY:
            return initialState;

        default:
            return state
    }
}