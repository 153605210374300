import { TokenManager } from '../Auth';
import { getHeaders } from '../Request';
import { API_BASE_URLS } from '../config.js';
import prepareComposeRequest from '../Request/prepareComposeRequest';

export const regionUrl = (region) => API_BASE_URLS[region] || '';

export const getBaseUrl = async () => {
    const region = await TokenManager.getRegion();
    return regionUrl(region);
};

export const getUrl = async (endpoint = '') => {
    const url = await getBaseUrl()
    return url.replace(/\/$/g, '') + '/' + endpoint.replace(/^\//g, '');
};

export const Get = (endpoint, params = {}) => prepareComposeRequest(getUrl, getHeaders)(endpoint, 'GET', params);
export const Post = (endpoint, params = {}) => prepareComposeRequest(getUrl, getHeaders)(endpoint, 'POST', params);
export const Delete = (endpoint, params = {}) => prepareComposeRequest(getUrl, getHeaders)(endpoint, 'DELETE', params);

export default {
    Get,
    Post,
    Delete
};