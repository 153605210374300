// Core
import React, { Component } from 'react';
import router from './router/router';
import states from './router/states';

// Router
import { pushStateLocationPlugin, UIRouterReact, UIView, UIRouter} from '@uirouter/react';

// Redux
import { Provider } from 'react-redux';

// Services
import GeckoStore from './services/GeckoStore';

// Styles
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/@fortawesome/fontawesome-pro/css/all.min.css';
import './App.css';

// Components
import { GeckoGlobal } from '@geckolabs/gecko-react-ui';
import AppHeader from './components/app-header/app-header';
import AppFooter from './components/app-footer/app-footer';

import useAuth from './Hooks/useAuth';


class App extends Component {
    constructor(props) {
        super(props);

        this.store = GeckoStore.redux.init();
    }

    render() {
        return (
            <Provider store={this.store}>
                <UIRouter router={router}>
                    <div>
                        <GeckoGlobal />
                    	
                        <AppHeader />

                        <div className="app-body">
                            <UIView />
                        </div>

                        <AppFooter />
                    </div>
                </UIRouter> 
            </Provider>
        );
    }
}

function AppWrapper(props) {
    // Deal with auth/claim code before router starts!
    const isAuthenticated = useAuth();

    if (!isAuthenticated) return null;

    return <App {...props} />;
}

export default AppWrapper;