// Core
import React, { Component } from 'react';

// Style
import './collapsible.css';

// Components


export default class Section extends Component {

    constructor() {
        super();
        
        this.state = {
            open: false,
            class: "section"
        }

        // Binds
        this.handleClick = this.handleClick.bind(this);
        this.renderButtonArray = this.renderButtonArray.bind(this);
        this.renderButtonArrayItem = this.renderButtonArrayItem.bind(this);
    }

    handleClick(){
        if(this.state.open) {
          this.setState({
            open: false,
            class: "section"
          });
        }else{
          this.setState({
            open: true,
            class: "section open"
          });
        }
    }


    renderTitle() {
        // Render custom title
        if(this.props.renderTitle) {
            return this.props.renderTitle(this.props.title);
        }

        // Default to text
        return this.props.title;
    }

    renderButtonArray() {
        if (this.props.buttonArray) {

            let buttons = this.props.buttonArray.map((item, key) => {
                return this.renderButtonArrayItem(item, key);
            });

            return (
                <div className="buttonArray d-flex align-items-center">
                    {buttons}
                </div>
            );
        }

        return null;
    }


    renderButtonArrayItem(item, key) {

        if(!item.onClick) {
            item.onClick = () => {}
        };

        return (
            <div key={key} className="buttonItem pointer" onClick={item.onClick}>
                <div className="buttonItemInner">
                    {item.render()}
                </div>
            </div>
        )
    }

 
    render() {
        return (
            <div className={this.state.class}>
                <button>toggle</button>

                {this.renderButtonArray()}
                
                <div className="sectionhead" onClick={this.handleClick}>{this.renderTitle()}</div>
                
                <div className="articlewrap">
                    <div className="article">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}