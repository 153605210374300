import { servicesPlugin, hashLocationPlugin } from '@uirouter/react';
import router from './index';

// Create a new instance of the Router
router.plugin(servicesPlugin);
router.plugin(hashLocationPlugin);

// Initial state when the router is first loaded
router.urlService.rules.initial({ state: 'dashboard' });
// Fallback page if state can't be found
router.urlService.rules.otherwise({ state: '404' });

// Start the router
//router.start();

export default router;