import './index.css';
import React, { Component } from 'react';
import { $state, $stateParams } from '../../router/';
import countries  from '../../Logic/misc/countries';
import regions  from '../../Logic/misc/regions';
import { GECKO_CONSTS, GeckoToastService, GeckoDialogService, GeckoCardForm } from '@geckolabs/gecko-react-ui';
import NewAccountControls from '../../components/new-account-btns';


export class NewChatAccountPage extends Component {

    state = {
        address: {
            street     : '',
            city       : '',
            region     : '',
            isoCountry : '',
            postalCode : '',
        },
        values: {
            region     : null,
            name       : '',
        }
    }

    changeHandler(obj, key, value) {
        if (obj === 'address') {
            const address = Object.assign({}, this.state.address, {[key]: value});
            return this.setState({address});
        }

        const values = Object.assign({}, this.state.values, {[key]: value});
        this.setState({values});
    }

    companyFields = [
        {
            key         : 'name',
            label       : 'Company',
            width       : '100%',
            required    : true,
        },
    ];

    addressFields = [
        {
            key          : 'street',
            label        : 'Street Address',
            required     : true,
            width        : '100%',
        },
        {
            key          : 'city',
            label        : 'City',
            required     : true,
            width        : '50%',
        },
        {
            key          : 'region',
            label        : 'State',
            required     : true,
            width        : '50%',
        },
        {
            key          : 'postalCode',
            label        : 'Post Code / Zipcode',
            required     : true,
            width        : '50%',
        },
        {
            key          : 'isoCountry',
            label        : 'Country',
            required     : true,
            width        : '50%',
            type         : GECKO_CONSTS.FIELD_TYPE_SELECT,
            options      : countries.map(c => ({value: c.iso2, label: c.name})),
            config       : {clearable: false} 
        }
    ];

    settingsFields = [
        {
            key          : 'region',
            label        : 'Region',
            width        : '100%',
            type         : GECKO_CONSTS.FIELD_TYPE_SELECT,
            required     : true, 
            options      : regions(),
            clearable    : true,
            config       : {clearable: false}  
        }
    ];

    btns = [{
        name    : 'Create Chat Account',
        icon    : 'check',
        preset  : GECKO_CONSTS.BTN_PRESET_SAVE,
        handler : this.submitHandler.bind(this)
    }];

    renderConfirmAlert() {
        return (
            <div className="create-form-account-alert">
                <p>Create an account with the following settings?</p>
                <ul>
                    <li>Name: <strong>{(this.state.values.name || '') || 'n/a'}</strong></li>
                    <li>Region: <strong>{(this.state.values.region || '').toUpperCase() || 'n/a'}</strong></li>
                </ul>
            </div>
        );
    }


    prepareData() {
        const data = this.state.values;
        // Address obj
        const address = this.state.address;
        if (data.name) address.customerName = data.name;

        return Object.assign({}, data, {address});
    }

    regionUrls = {
        'eu'    : 'https://api-euwest2.geckochat.io',
        'us-e'  : 'https://api-useast1.geckochat.io',
        'stage' : 'https://api-eustage.geckochat.io',
        'local' : 'http://localhost:5000'
    };

    saveFn() {
        const endpoint = `${this.regionUrls[this.state.values.region]}/account`;
        
        return fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.prepareData())
        })
        .then(res => {
            // Throw Error
            if(res.status !== 200) { 
                return res.json().then(err => {
                    throw new Error(err.name);
                }); 
            }
            return res.json();
        });
    }

    submitHandler() {
        if (!this.state.values.region) return new GeckoToastService().error('Please select a region.');

        return new GeckoDialogService().confirm(this.renderConfirmAlert()).then(ok => {
            if (ok) {
                this.saveFn().then((alert) => {
                    // Success toast
                    new GeckoToastService().success('Account has been created and will be ready soon.');
                    // Redirect
                    return $state.go('dashboard');
                }).catch(err => new GeckoToastService().error(String(err)));
            }
        });
    }

    render() {
        return (
            <div className="mainContentWrapper p-2--sm p-5">
                
                <NewAccountControls />

               <GeckoCardForm 
                   name="Company Details"
                   fields={this.companyFields} 
                   values={this.state.values} 
                   handler={this.changeHandler.bind(this, 'values')} />
                <GeckoCardForm 
                   name="Company Address"
                   fields={this.addressFields} 
                   values={this.state.address} 
                   handler={this.changeHandler.bind(this, 'address')} />
                <GeckoCardForm 
                   name="Account Settings"
                   fields={this.settingsFields} 
                   values={this.state.values} 
                   handler={this.changeHandler.bind(this, 'values')} 
                   submitHandler={this.submitHandler.bind(this)}
                   footerBtns={this.btns} />
            </div>
        );
    }

}

export default NewChatAccountPage;