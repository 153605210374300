import { GeckoCard, GeckoIcon, GeckoFields, GeckoToastService, GeckoDialogService } from '@geckolabs/gecko-react-ui';
import { UncontrolledTooltip } from 'reactstrap';
import { useState } from 'react';
import { Post } from '../../../../services/ApiRequest';

const ConfirmMessage = ({isNewEngageWebApp, accountId, accountName}) => {
    return (
        <>
            <p>
                <strong style={{fontWeight:700}}>{accountId} - {accountName}</strong>
            </p>
            <p>
                {isNewEngageWebApp ? (
                    <>Are you sure you want to migrate this account to use the <strong style={{fontWeight:700}}>NEW Engage</strong> web app?</>
                ) : (
                    <>Are you sure you want to migrate this account back to the <strong style={{fontWeight:700}}>OLD Engage</strong> web app?</>
                )}
            </p>
        </>
    );
};

const migrateFn = ({accountId, accountUuid, accountName, isNewEngageWebApp, setIsNewEngageWebApp}) => {

    const message = <ConfirmMessage {...{isNewEngageWebApp, accountId, accountName}} />

    return new GeckoDialogService().confirm(message).then(ok => {
        if (ok) {
            Promise.all([
                Post('/switchApp/profile', {
                    AccountId: accountUuid, // ie the account uuid
                    ExternalId: null, // ie the user auth_id OR null for every user in the account
                    FromAppId: isNewEngageWebApp ? 'geckoform' : 'geckoengage',
                    ToAppId: isNewEngageWebApp ? 'geckoengage' : 'geckoform',
                }),
                Post(`/accounts/${accountId}`, {features: { new_engage_web_app: isNewEngageWebApp }})
            ]).then(res => {
                if (isNewEngageWebApp) {
                    new GeckoToastService().success(`Account ${accountId} has been migrated to NEW Engage.`);
                } else {
                    new GeckoToastService().success(`Account  ${accountId} has been migrated back to OLD Engage.`);
                }
            }).then(() => {
                // Only update the toggle after updating
                setIsNewEngageWebApp(isNewEngageWebApp); 
            }).catch(console.log);
        }
    });
};

export default function ({accountId, accountUuid, accountName, accountIsNewEngageWebApp}) {
    const [isNewEngageWebApp, setIsNewEngageWebApp] = useState(accountIsNewEngageWebApp || false);

    return (
        <GeckoCard>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">
                    <div className="row">
                        <div className="col-9 col-sm-7 col-md-8 col-lg-9 d-flex align-items-center">
                            Enable the New Engage Web App?

                            <div id={'is_new_engage_web_app_description'} className="feature-toggle-description">
                                <GeckoIcon icon="info-circle" />
                                <UncontrolledTooltip delay={0} placement="top" target={'is_new_engage_web_app_description'}>
                                    A better, faster, and more accessible version of the Engage web app.
                                </UncontrolledTooltip>
                            </div>
                        </div>                        

                        <div className="col-3 col-sm-5 col-md-4 col-lg-3 d-flex justify-content-end liteToggleWrapper">
                            <GeckoFields 
                                fields={[{
                                    key: 'isNewEngageWebApp',
                                    label: '',
                                    type: 'toggle'
                                }]} 
                                values={{isNewEngageWebApp}}
                                handler={(key, isNewEngageWebApp) => migrateFn({accountId, accountUuid, accountName, isNewEngageWebApp, setIsNewEngageWebApp})}
                            />       
                        </div>

                    </div>
                </li>
            </ul>
       </GeckoCard>
    );
};