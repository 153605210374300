export default {
    get(key) {
        return window.localStorage.getItem(key);
    },
    set(key, value) {
        return window.localStorage.setItem(key, value);
    },
    remove(key) {
        return localStorage.removeItem(key);
    }
};
