import ChangelogsPage from './changelogs';

export default {
    name: 'changelogs-chat',
    url: '/changelogs/chat?{page}&{keyword}',
    component: ChangelogsPage,
    resolve: [
        {   
            token: 'changelogs',
            resolveFn: () => {
                return fetch('https://s3.eu-west-2.amazonaws.com/gecko-chat-changelogs/changelogs.json')
                    .then(res => {
                        if(res.status !== 200) {
                            return res.text().then(err => {throw new Error(err)});
                        }
                        return res.json();
                    })
            }
        },
    ]
};