import { TokenManager } from '../Auth';
import { getHeaders } from '../Request';
import { prepareComposeRequest } from '../Request/prepareComposeRequest';

const getBaseUrl = () => {
    return TokenManager.fromStorage()
        .then((token) => {
            const {IdToken} = token;
            return TokenManager.decode(IdToken);
        })
        .then(decodedToken => {
            return decodedToken.api;
        })
        .catch(err => {
            // If we just don't have the tokens in storage return an empty string
            if(err.message === 'Insufficient tokens provided') { return ''; }
            // otherwise rethrow the error
            throw err;
        });
};

export const getUrl = async (endpoint = '') => {
    const url = await getBaseUrl();
    return url.replace(/\/$/g, '') + '/' + endpoint.replace(/^\//g, '');
};

export const Get = (endpoint, params = {}) => prepareComposeRequest(getUrl, getHeaders)(endpoint, 'GET', params);
export const Post = (endpoint, params = {}) => prepareComposeRequest(getUrl, getHeaders)(endpoint, 'POST', params);
export const Delete = (endpoint, params = {}) => prepareComposeRequest(getUrl, getHeaders)(endpoint, 'DELETE', params);

export default {
    Get,
    Post,
    Delete
};