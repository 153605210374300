import Gecko from '../gecko/gecko';
import { GECKO_CONSTS, GeckoToastService } from '@geckolabs/gecko-react-ui';
import Utils from '../../Utils';

// Redux
import { overwriteAccounts, addAccounts } from '../../modules/accounts';

// Services
import GeckoStore from '../../services/GeckoStore';

import { Get, Post } from '../../services/ApiRequest';


export default class AccountLogic {

	/**
	 * Get all accounts
	 *
	 * @param bool persist - should the data be persisted to redux
	 * @return collection of accounts
	 *
	 */
	static get(persist = true, passedParams = {}) {
		let defaultParams = {
			account_rfields: 'created_at,id,name',
			package_rfields: 'title',
			order_by: 'id|ASC',
			page: 1,
			per_page: 10000,
			include: 'package',
		};

		let params = Object.assign({}, defaultParams, passedParams);

		return Get('accounts/', params)
		.then((response) => {
			if(persist) {
        		GeckoStore.redux.dispatch(addAccounts(response.data));
        	}

        	return response;
		});
	}

	buildFeatureToggles(accountPackage = {}, values = false) {
		let data = (values ? {} : []);

        if (accountPackage) {
            let c = 0;

            // Loop round the package object
            for (let prop in accountPackage){

            	// If the key is a feature then add it
                if (prop.indexOf('feature_') !== -1 || prop.indexOf('new_') !== -1) {

                	if(values == false) {
	                    data.push({
	                        key: prop,
	                        label: Utils.capitalize(prop.replace('feature_', '').split('_').join(' '), true),
	                        type: GECKO_CONSTS.FIELD_TYPE_TOGGLE,
	                        width: '16.6%',
	                        break: !c ? true : false,
	                        val: (typeof accountPackage[prop] === 'boolean' ? accountPackage[prop] : false),
	                        searchTags: [prop, 'feature'].join(' '),
	                    });
	                } else {
	                	data[prop] = accountPackage[prop];
	                }

                    c++;
                }
            }
        }

        return data;
    }

    // Limit fields
    buildLimitInputs(accountPackage = {}){
    	let data = [];	

        if (accountPackage) {
            for (let prop in accountPackage){

            	if (prop.indexOf('allowed_') !== -1) {
	                //Add toogle to fields array
	                data.push({
	                	'allowed':{
		                    key: prop + '_allowed',
		                    label: Utils.capitalize(prop.split('_').join(' '), true),
		                    type: GECKO_CONSTS.FIELD_TYPE_NUMBER,
		                    width: '100%',
		                    val: accountPackage[prop].allowed,
		                },
		                'available':{
		                    key: prop + '_available',
		                    label: Utils.capitalize(prop.split('_').join(' '), true),
		                    type: GECKO_CONSTS.FIELD_TYPE_NUMBER,
		                    width: '100%',
		                    val: accountPackage[prop].available,
		                },
		                'used':{
		                    key: prop + '_used',
		                    label: Utils.capitalize(prop.split('_').join(' '), true),
		                    type: GECKO_CONSTS.FIELD_TYPE_NUMBER,
		                    width: '100%',
		                    val: accountPackage[prop].used,
		                },
		                searchTags: [prop, 'limit', 'allowed'].join(' '),
		            });
	            }
            }
        }

        return data;
    }

    static findExport(id) {
    	console.warn('the findExport() method has been deprecated');
    }

    /**
    *
    */
    static addTransaction(data) {
        return Post('/transactions/add-voip-credit', data)
        .then((resp) => {
			if (resp.success) {
				new GeckoToastService().success('Account saved successfully.');
			}
        }).catch((err) => {
            new GeckoToastService().error(err);
        });
    }

	static addToQueue(data) {
		return Post('/' + data.type + 's/add_to_queue', data);
	}

	static markAsFailed(data) {
		return Post('/' + data.type + 's/mark_as_failed', data);
	}
};
