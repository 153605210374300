import React, { Component } from 'react';
import { UISref } from '@uirouter/react';
import { $state, $stateParams } from '../../router/';
import { GeckoCard } from '@geckolabs/gecko-react-ui';

export class NewAccountControls extends Component {

    btnClass(state) {
        return this.isActive(state) ? 'btn-info' : 'btn-default'
    }

    isActive(state) {
        return state === $state.$current.name;
    }

    render() {
        return (
             <GeckoCard padded={true}>
                <div class="btn-group d-flex align-items-center justify-content-center" role="group">
                    <UISref to="new-form-account">
                        <button type="button" className={`btn gecko-ui-btn ${this.btnClass('new-form-account')}`} disabled={this.isActive('new-form-account')}>New Form Account</button>
                    </UISref>   
                    <UISref to="new-chat-account">
                        <button type="button" className={`btn gecko-ui-btn ${this.btnClass('new-chat-account')}`} disabled={this.isActive('new-chat-account')}>New Chat Account</button>
                    </UISref>
                </div>
            </GeckoCard>
        );
    }

}

export default NewAccountControls;