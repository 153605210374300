export default () => ([
    {
        value: 'eu', 
        label: 'Europe'
    },
    {
        value: 'us-e', 
        label: 'US'
    },
    {
        value: 'stage', 
        label: 'Stage'
    },
    {
        value: 'local', 
        label: 'Developer'
    }
].filter(r => {
    if (window.location.href.indexOf('manage.geckoform.com') !== -1) {
        return r.value === 'eu' || r.value === 'us-e';
    }
    if (window.location.href.indexOf('manage-stage.geckoform.com') !== -1) {
        return r.value === 'stage';
    }
    return r.value === 'local';
}));

