const REGION_LOCAL = 'local';
const REGION_STAGE = 'stage';
const REGION_EU    = 'eu';
const REGION_US_E  = 'us-e';

export const API_BASE_URLS = {
    [REGION_LOCAL] : 'http://localhost:8000',
    [REGION_STAGE] : 'https://api-stage.geckoform.com',
    [REGION_EU]    : 'https://api-eu.geckoform.com',
    [REGION_US_E]  : 'https://api-us-e.geckoform.com'
};