import { useState, useEffect } from 'react';
import { TokenManager, AuthRequest, refreshAuthToken, getClaimCode } from '../../services/Auth';

function useAuth() {

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const claimCode        = getClaimCode();
        const authCheckPromise = claimCode ? TokenManager.claimCode(claimCode).then(() => {
            window.location.href = '/#/dashboard';
        }) : refreshAuthToken();
        
        authCheckPromise.then(() => {
            setIsAuthenticated(true);
        }).catch(err => {
            setIsAuthenticated(false);
            window.location.href = AuthRequest.getAuthRedirectUrl()
            //console.log('--- TOKENS WERE BAD ---');
        });

    }, []);

    return isAuthenticated;
}

export default useAuth;