import router from './index';
import { Gecko } from '../Logic';
import { GeckoLoadingService } from '@geckolabs/gecko-react-ui';

router.transitionService.onBefore(true, (trans) => {
    console.log('Nav Start');

    // Redirect away from login view if authenticated
    if(trans.to().name ===  'login' && window.TokenManager.hasTokens()) {
        return trans.router.stateService.target('dashboard');
    }

    // TODO:: redirect away form protected views if unauthenticated

    closeSidebar();

    // Show loading indicator
    new GeckoLoadingService().show();
});

router.transitionService.onSuccess(true, (trans) => {
    console.log('Nav End');

    // Remove init loading (on first load)
    if (window.removeLoading) setTimeout(() => window.removeLoading(), 500);

    // Scroll to the top of the page
    window.scrollTo(0, 0);
    // Hide loading indicator
    new GeckoLoadingService().hide();
});

router.transitionService.onError(true, (err) => {
    console.log('Nav Error', err);
    // Hide loading indicator
    new GeckoLoadingService().hide();
});


function closeSidebar() {
    if(window.sidebar) {
        window.sidebar.classList.remove('mdc-drawer--open');
    }
}

export default router;