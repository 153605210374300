import redux from './redux.js';
import local from './localStorage.js';

const stores = { redux, local };

const GeckoStore = {
    ...stores,
    get(type, key) {
        return new Promise(resolve => resolve())
            .then(() => this[type].get(key))
    },
    set(type, key, value) {
        return new Promise(resolve => resolve())
            .then(() => this[type].set(key, value))
    }
}

export default GeckoStore; 