import encodeQueryString from 'querystring-es3';
import { cleanGetParams } from '../';

export const executeRequest = async (url, headers, method, params) => {
    const response = await (() => {
        if (method === 'POST') {
            return window.fetch(url, {
                method: method,
                body: JSON.stringify(params),
                headers: headers
            });
        } else if (method === 'DELETE') {
            return window.fetch(url, {
                method: method,
                headers: headers
            });
        } else {
            const queryString = `?${encodeQueryString.stringify(cleanGetParams(params))}`
            return window.fetch(`${url}${queryString}`, {
                method: method,
                headers: headers
            });
        }
    })();

    // Is request success status?
    const isSuccessResponse = [200, 201, 202, 204, 206].includes(response.status);

    // Preserve request promise
    return new Promise ((resolve, reject) => { response.json().then(result => isSuccessResponse ? resolve(result) : reject(result)) });
};

export default executeRequest;