import {GECKO_CONSTS, GeckoToastService, GeckoModalService} from "@geckolabs/gecko-react-ui";
//import GeckoModalService from "@geckolabs/gecko-react-ui/lib/services/modal/modal";
import AccountLogic from "../Account";

export function buildModalButtons(info, action) {
    return [
        {
            name: 'Cancel',
            preset: GECKO_CONSTS.BTN_PRESET_CLOSE,
            handler: () => {
                return new GeckoModalService().close();
            }
        },
        {
            name: info.name,
            preset: GECKO_CONSTS.BTN_PRESET_SAVE,
            handler: () => {
                const data = {
                    accountId: info.accountId,
                    type: info.type,
                    id: info.id,
                };
                AccountLogic[action](data)
                    .then((response) => {
                        new GeckoToastService().success(response.message);
                    }).catch((error) => {
                    new GeckoToastService().error(error.message);
                }).finally(() => {
                    return new GeckoModalService().close();
                });
            }
        }
    ];
}
